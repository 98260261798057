import axios from "axios";

export const getQuote = async (incurrency, outcurrency, inamount, slippage) => {
  const apiUrl = `https://degeninsureserver.onrender.com/api/wayfinder?inTokenAddress=${incurrency}&outTokenAddress=${outcurrency}&amount=${inamount}&gasPrice=25&slippage=${slippage}`;

  try {
    const response = await axios.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    });
    return response.data.data;
  } catch (error) {
    console.error(error);
  }
};
