import {
  Box,
  Flex,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  useToast,
  Link,
  Grid,
  GridItem,
  Avatar,
  Badge,
  HStack,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import { ethers } from "ethers";
import { newAbi } from "../resources/newAbi.js";
import AppNav from "../components/AppNav";
import { fetchUserByAddress } from "../helpers/getUserData";
import { useAccount } from "wagmi";
import axios from "axios";
import Confetti from "react-confetti";
import { degenInsureAbi } from "../resources/degenInsureAbi.js";
import { HiArrowLongRight } from "react-icons/hi2";

const DappCover = ({ ethPrice }) => {
  const [coverAmt, setCoverAmt] = useState(0);
  const [displayName, setDisplayName] = useState("");
  const [appName, setAppName] = useState("");
  const [premium, setPremium] = useState(0);
  const protocols = [
    {
      name: "Uniswap",
      appName: "uniswap",
      color: "#fc72ff",
      logo: "https://cryptologos.cc/logos/uniswap-uni-logo.png?v=026",
      type: "Smart Contract Risk",
      description:
        "This cover type gives up to 95% refund depending on your $DGNS holdings and covers exploits and hacks of Uniswap V3 smart contracts, as well as sudden and severe economic events like oracle manipulation, governance attacks.",
    },
    {
      name: "USDC",
      appName: "usdc",
      color: "#2774ca",
      logo: "https://cryptologos.cc/logos/usd-coin-usdc-logo.png?v=026",
      type: "De-Peg Risk",
      description:
        "This cover type gives full 100% refunds, no questions asked in the case of USDC depeg events up to $0.85 within the first 24 hours, and $0.95 within the first 48 hours.",
    },
    {
      name: "Unibot",
      appName: "unibot",
      color: "#6a25ad",
      logo: "https://stakingcrypto.info/static/assets/coins/unibot-logo.png?v=101",
      type: "Loss of Funds",
      description:
        "This cover type is tailored specifically for Unibot users, and refunds up to 90% of lost funds in the event of overly high slippage being used and fat fingers on limit orders. ",
    },
    {
      name: "AssureDeFi",
      appName: "assure",
      color: "#e1d343",
      logo: "https://miro.medium.com/v2/resize:fit:2400/1*6LAEF3MsrZFo82tvdsaWpQ.jpeg",
      type: "Rug pulls and soft rugs",
      description:
        "This cover type is tailored specifically for AssureDeFi KYCd projects. It offers full 100% reimbursements to users affected by projects that rug even after being covered by AssureDeFi. ",
    },

    {
      name: "BananaGun",
      appName: "banana",
      color: "#ffec00",
      logo: "https://pbs.twimg.com/profile_images/1669901176198791169/NejGREje_400x400.jpg",
      type: "Loss of Funds",
      description:
        "This cover type is tailored specifically for BananaGunBot users, and refunds up to 90% of lost funds in the event of overly high slippage being used and fat fingers on limit orders.",
    },
    {
      name: "AimBot",
      appName: "aimbot",
      color: "#d0c3f5",
      logo: "https://assets-global.website-files.com/64b98ae8b7e1288cf71ec395/64c972302785e6db2d2b6bab_D.svg",
      type: "Loss of Funds",
      description:
        "This cover type is tailored specifically for Aimbot users, and refunds up to 90% of lost funds in the event of overly high slippage being used and fat fingers on limit orders.",
    },
    {
      name: "Solana",
      appName: "solana",
      color: "#7a62ec",
      logo: "https://cryptologos.cc/logos/thumbs/solana.png?v=030",
      type: "Trading bots & airdrop claims",
      description:
        "Get cover against losses while using trading bots and airdrop claims.",
    },
  ];
  const points = 10;
  const [userCovers, setUserCovers] = useState(0);
  const { address } = useAccount();
  const [weiValue, setWeiValue] = useState(0);
  const [userId, setUserId] = useState("");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isPurchaseOpen,
    onOpen: onPurchaseOpen,
    onClose: onPurchaseClose,
  } = useDisclosure();
  const {
    isOpen: isSuccessOpen,
    onOpen: onSuccessOpen,
    onClose: onSuccessClose,
  } = useDisclosure();
  const {
    isOpen: isWarningOpen,
    onOpen: onWarningOpen,
    onClose: onWarningClose,
  } = useDisclosure();
  const [usdValue, setUsdValue] = useState(0);
  const [ethValue, setEthValue] = useState("ETH Value");
  const [userInputETH, setUserInputETH] = useState(0);
  const [coverPrice, setCoverPrice] = useState(0);
  const [isPaying, setIsPaying] = useState(false);
  const toast = useToast();

  const provider =
    /*     window.ethereum &&  */ new ethers.providers.Web3Provider(
      window.ethereum
    ); //change to be RPC URL provider for separate states
  const contractAddress = "0x76809F492F1c19dF8EcbA175518d9E0E0366cE06";
  const contract = new ethers.Contract(contractAddress, newAbi, provider);

  const calculateWeiValue = () => {
    try {
      const ethValueInWei = ethers.utils.parseEther(userInputETH.toString());
      setWeiValue(ethValueInWei);
    } catch (e) {}
  };

  useEffect(() => {
    calculateWeiValue();
  }, [userInputETH]);

  async function calculatePayment(ethEquivalent, protocol) {
    try {
      // Convert ethEquivalent to Wei using ethers.utils.parseEther
      const ethValueInWei = ethers.utils.parseEther(ethEquivalent.toString());

      // Call the contract's calculatePayment function
      const paymentAmount = await contract.calculatePayment(
        ethValueInWei,
        protocol
      );

      // Convert the paymentAmount from BigNumber to a string
      const formattedPayment = ethers.utils.formatEther(paymentAmount);

      setCoverPrice(formattedPayment);

      return formattedPayment;
    } catch (error) {}
  }

  async function purchaseInsurance(ethEquivalent) {
    try {
      setIsPaying(true);
      // Ensure the user is connected to an Ethereum wallet
      if (!provider.getSigner()) {
        throw new Error("Please connect your Ethereum wallet.");
      }

      // Connect to the contract with a signer (user's wallet)
      const contractWithSigner = contract.connect(provider.getSigner());

      // Calculate the payment amount
      const paymentAmount = await calculatePayment(ethEquivalent, appName);

      if (!paymentAmount) {
        throw new Error("Payment amount is invalid.");
      }

      // Send a transaction to the contract's purchaseInsurance function
      const tx = await contractWithSigner.purchaseInsurance(
        ethers.utils.parseEther(ethEquivalent.toString()),
        appName,
        {
          value: ethers.utils.parseEther(paymentAmount.toString()), // Convert ETH to Wei
        }
      );

      await tx.wait();

      toast({
        description: "Plan successfully purchased",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      setIsPaying(false);
      handleUpdateDegenPoints();
      onSuccessOpen();
    } catch (error) {
      toast({
        description: "Error making purchase",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      setIsPaying(false);
    }
  }

  const handleBlur = (e) => {
    const newValue = e.target.value;
    setUsdValue(newValue);
  };

  const handleBlur2 = (e) => {
    const newValue = e.target.value;
    setUserInputETH(newValue);
  };

  const calculateETHValue = () => {
    const ETHValue = usdValue / ethPrice;
    setEthValue(ETHValue);
  };

  useEffect(() => {
    calculateETHValue();
  }, [usdValue, ethPrice]);

  useEffect(() => {
    calculatePayment(userInputETH, appName);
  }, [userInputETH]);

  useEffect(() => {
    try {
      if (!window.ethereum) {
        onWarningOpen(); // Automatically open the modal
      }
    } catch (e) {}
  }, [window.ethereum]);

  const fetchDatas = async () => {
    const datas = await fetchUserByAddress(address);
    setUserId(datas?._id);
  };

  useEffect(() => {
    fetchDatas();
  }, [address]);

  const handleUpdateDegenPoints = async () => {
    try {
      const response = await axios.put(
        `https://degeninsureserver.onrender.com/api/users/${userId}/updateDegenPoints`,
        { points },
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_API_KEY,
          },
        }
      );

      // You can update the UI to reflect the updated degenPoints if needed
    } catch (error) {}
  };

  const getNumberOfUserCovers = async (
    contractAddress,
    contractAbi,
    userAddress
  ) => {
    try {
      // Initialize a Web3 provider
      const provider = new ethers.providers.Web3Provider(window.ethereum);

      // Instantiate the contract using the provided contract address and ABI
      const contract = new ethers.Contract(
        contractAddress,
        contractAbi,
        provider
      );

      // Call the insurances function to retrieve insurance data
      const insurance = await contract.getNumberOfCoverPlans(userAddress);

      // Return the retrieved insurance data
      return insurance;
    } catch (error) {
      /*    throw error; */
    }
  };

  const fetchUserPlans = async () => {
    const plans = await getNumberOfUserCovers(
      "0x76809F492F1c19dF8EcbA175518d9E0E0366cE06",
      degenInsureAbi,
      address
    );
    setUserCovers(plans?.toString());
  };

  useEffect(() => {
    fetchUserPlans();
  }, [address]);

  const handleSetPremiumRate = async () => {
    /*   if (contract && premiumRate) { */
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      // Instantiate the contract using the provided contract address and ABI
      const contract = new ethers.Contract(
        "0x76809F492F1c19dF8EcbA175518d9E0E0366cE06",
        newAbi,
        signer
      );
      const tx = await contract.setPremiumRate("uniswap", "150");
      await tx.wait();
    } catch (error) {}
    /*     } */
  };

  const getPremiumsAndProtocols = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(
        "0x76809F492F1c19dF8EcbA175518d9E0E0366cE06",
        newAbi,
        signer
      );
      const protocolList = await contract.protocolInfo(appName);
      setPremium(String(protocolList));
    } catch (e) {}
  };

  useEffect(() => {
    getPremiumsAndProtocols();
  }, [appName]);

  const withdrawFunds = async () => {
    await contract.withdrawFunds();
  };

  return (
    <div>
      <AppNav />
      <Flex direction={{ base: "column", md: "row" }}>
        <Box>
          <Sidebar />
        </Box>

        <Flex
          justifyContent="center" // Center horizontally
          alignItems="center" // Center vertically
          flex="1" // Take up available space
          p={{ base: "20px", md: "30px" }}
          direction="column"
          mt={{ base: "0px", md: "200px", lg: "0px" }}
        >
          <Grid
            mt="40px"
            templateRows={{ base: "repeat(3,1fr)", md: "repeat(1,1fr)" }}
            templateColumns={{ base: "repeat(1,1fr)", md: "repeat(3,1fr)" }}
            gap={10}
          >
            {protocols?.map((e, i) => {
              return (
                <GridItem
                  w={{ base: "300px", md: "250px", lg: "300px" }}
                  h="400px"
                  borderRadius="20px"
                  p="20px"
                  background={`linear-gradient(to bottom, ${e?.color}, black)`}
                >
                  <Box
                    w="50px"
                    h="50px"
                    borderRadius="50%"
                    backgroundColor="white"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mt="20px"
                  >
                    <Avatar
                      name={e?.name}
                      src={e?.logo}
                      color={e?.color}
                      fontSize="32px"
                    />
                  </Box>
                  <Box mt="20px" fontSize="25px">
                    {e?.name}
                  </Box>
                  <Badge>{e?.type}</Badge>
                  <Box h="110px" mt="20px" fontSize="12px">
                    {e?.description}
                  </Box>

                  <Button
                    isLoading={isPaying}
                    onClick={() => {
                      setDisplayName(e?.name);
                      setAppName(e?.appName);
                      onPurchaseOpen();
                    }}
                    w="100%"
                    mt="15px"
                    borderRadius="10px"
                  >
                    Purchase
                  </Button>
                </GridItem>
              );
            })}
          </Grid>
        </Flex>
      </Flex>

      <Modal
        size={{ base: "full", md: "md" }}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent
          bg="black"
          border="1.5px solid #181818"
          borderRadius="15px"
        >
          <ModalHeader>ETH converter</ModalHeader>
          <ModalCloseButton
            _hover={{
              color: "#22e9a0",
              borderRadius: "10px",
              border: "1px solid #22e9a0",
            }}
          />
          <ModalBody>
            <Box>
              <Input
                focusBorderColor="#22e9a0"
                borderRadius="8px"
                placeholder="Enter amount in USD"
                onBlur={handleBlur}
              />
            </Box>

            <Flex
              mt="10px"
              w="100%"
              alignItems="center"
              justifyContent="space-between"
            >
              <Input
                focusBorderColor="#22e9a0"
                borderRadius="8px"
                placeholder="ETH value"
                value={Number(ethValue)?.toFixed(3) + " ETH"}
              />
              <Box ml="5px">
                {" "}
                <Button
                  _hover={{ bg: "#22e9a0", color: "black" }}
                  borderRadius="10px"
                  onClick={() => {
                    setUserInputETH(ethValue);
                    onClose();
                  }}
                >
                  Click to set
                </Button>
              </Box>
            </Flex>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        size={{ base: "full", md: "xs" }}
        isOpen={isWarningOpen}
        onClose={onWarningClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          bg="black"
          border="1.5px solid #181818"
          borderRadius="20px"
        >
          <ModalHeader color="#22e9a0">
            Your experience may be limited
          </ModalHeader>
          <ModalCloseButton
            _hover={{
              color: "#22e9a0",
              borderRadius: "10px",
              border: "1px solid #22e9a0",
            }}
          />
          <ModalBody>
            {" "}
            {/*   <Lottie loop="false" animationData={completed} /> */}
            <Box>
              It seems you either haven't installed Metamask or you're not using
              DegenInsure in a wallet's dApp browser. For the best experience,
              please use DegenInsure in a wallet browser or install Metamask or
              any other wallet.
            </Box>
            <Link
              href="https://ethereum.org/en/wallets/find-wallet/"
              isExternal
            >
              <Button
                _hover={{ bg: "#22e9a0", color: "black" }}
                w="100%"
                mt="10px"
                borderRadius="10px"
              >
                Get a wallet
              </Button>
            </Link>
            <Link isExternal href="https://ethereum.org/en/wallets/">
              {" "}
              <Button
                _hover={{ bg: "#22e9a0", color: "black" }}
                w="100%"
                mt="10px"
                borderRadius="10px"
              >
                What's a wallet?
              </Button>
            </Link>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>

      <Box>
        {isSuccessOpen && <Confetti />}
        <Modal
          size={{ base: "full", md: "xs" }}
          isOpen={isSuccessOpen}
          onClose={onSuccessClose}
          isCentered
        >
          <ModalOverlay />
          <ModalContent
            bg="black"
            border="1.5px solid #181818"
            borderRadius="20px"
          >
            <ModalHeader color="#22e9a0">Plan purchase successful!</ModalHeader>
            <ModalCloseButton
              _hover={{
                color: "#22e9a0",
                borderRadius: "10px",
                border: "1px solid #22e9a0",
              }}
            />
            <ModalBody>
              You've just bought a DegenInsure plan and earned{" "}
              <Box color="#22e9a0" as="span" display="inline-block">
                10
              </Box>{" "}
              DegenPoints! Congratulations! See what you can do with your
              DegenPoints in our{" "}
              <Link href="https://degeninsure.gitbook.io/welcome/">
                {" "}
                <Box color="#22e9a0" as="span" display="inline-block">
                  docs
                </Box>
              </Link>{" "}
              or view how many DegenPoints you have in your{" "}
              <Link href="/app/profile">
                <Box color="#22e9a0" as="span" display="inline-block">
                  profile
                </Box>
              </Link>
              .
            </ModalBody>

            <ModalFooter></ModalFooter>
          </ModalContent>
        </Modal>

        <Modal
          size={{ base: "full", md: "sm" }}
          isOpen={isPurchaseOpen}
          onClose={onPurchaseClose}
        >
          <ModalOverlay />
          <ModalContent
            bg="black"
            border="1.5px solid #181818"
            borderRadius="15px"
          >
            <ModalHeader>Buy {displayName} cover</ModalHeader>
            <ModalCloseButton
              _hover={{
                color: "#22e9a0",
                borderRadius: "10px",
                border: "1px solid #22e9a0",
              }}
            />
            <ModalBody>
              <Flex w="100%" alignItems="center" justifyContent="space-between">
                <Box>Protocol</Box>
                <Box color="#22e9a0">{displayName}</Box>
              </Flex>

              <Flex
                mt="10px"
                w="100%"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box>Premium</Box>
                <Box color="#22e9a0">{Number(premium) / 100}%</Box>
              </Flex>

              <Flex
                mt="10px"
                w="100%"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box>Cover period</Box>
                <Box color="#22e9a0">1 month</Box>
              </Flex>

              <Flex
                mt="10px"
                w="100%"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box>You'll have to pay</Box>
                <Box color="#22e9a0">{coverPrice} ETH</Box>
              </Flex>
              <Box mt="20px">
                <Input
                  focusBorderColor="#22e9a0"
                  borderRadius="8px"
                  placeholder="How much are you covering (in ETH)"
                  onBlur={handleBlur2}
                />
              </Box>

              <Flex
                mt="10px"
                w="100%"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box>Current ETH price</Box>
                <Box color="#22e9a0">${Number(ethPrice)?.toFixed(3)}</Box>
              </Flex>

              <Button
                _hover={{ bg: "#22e9a0", color: "black" }}
                borderRadius="10px"
                w="100%"
                mt="10px"
                onClick={() => purchaseInsurance(userInputETH)}
              >
                Buy Cover
              </Button>
            </ModalBody>

            <ModalFooter
              textAlign="center"
              alignItems="center"
              justifyContent="center"
            >
              <Link
                href="https://degeninsure.gitbook.io/welcome/protocol-cover"
                isExternal
                _hover={{ textDecoration: "none", color: "#22e9a0" }}
              >
                <HStack>
                  <Box textAlign="center">Get exact details in our docs</Box>
                  <Box>
                    <HiArrowLongRight />
                  </Box>
                </HStack>
              </Link>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>

      {/*    <Button onClick={() => handleSetPremiumRate()}>Set premium</Button> */}
    </div>
  );
};

export default DappCover;
