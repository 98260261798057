import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import GetAPlan from "./pages/GetAPlan";
import SignUp from "./pages/SignUp";
import Profile from "./pages/Profile";
import Assets from "./pages/Assets";
import axios from "axios";
import "./App.css";
import ClaimPayouts from "./pages/ClaimPayouts";
import SetUp from "./pages/SetUp";
import Login from "./pages/Login";
import Home from "./pages/Home";
import FourOhFour from "./pages/FourOhFour";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TOS from "./pages/TOS";
import Mandatory from "./pages/Mandatory";
import Vote from "./pages/Vote";
import Proposal from "./pages/Proposal";
import DEX from "./insuredApps/DEX/DEX";
import Leaderboard from "./pages/Leaderboard";
import DappCover from "./pages/DappCover";
import Yields from "./pages/Yields";

function App() {
  const [price, setPrice] = useState(0);

  useEffect(() => {
    axios
      .get(
        "https://apiv5.paraswap.io/prices?srcToken=0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE&srcDecimals=18&destToken=0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48&destDecimals=6&amount=1000000000000000000&side=SELL&network=1 "
      )
      .then((data) => {
        setPrice(data?.data?.priceRoute?.destUSD);
      });
  }, []);

  return (
    <div>
      <Routes>
        <Route path="/" element={<Home ethPrice={price} />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/tos" element={<TOS />} />
        <Route path="/disclaimer" element={<Mandatory />} />
        <Route path="/app/signup" element={<SignUp />} />
        <Route path="/app/login" element={<Login />} />
        <Route path="/app/setup" element={<SetUp />} />
        <Route path="/app/getplan" element={<GetAPlan ethPrice={price} />} />
        <Route
          path="/app/dapp-cover"
          element={<DappCover ethPrice={price} />}
        />
        <Route path="/app/profile" element={<Profile />} />
        <Route path="/app/assets" element={<Assets />} />
        <Route path="/app/claim" element={<ClaimPayouts />} />
        <Route path="/app/vote" element={<Vote />} />
        <Route path="/app/proposal/:id" element={<Proposal />} />
        <Route
          path="/app/trade/:incurrency/:inamount/:outcurrency"
          element={<DEX />}
        />
        <Route path="/competition/leaderboard" element={<Leaderboard />} />
        <Route path="/app/yields" element={<Yields />} />
        <Route path="/*" element={<FourOhFour />} />
      </Routes>
    </div>
  );
}

export default App;
