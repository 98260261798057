import React from "react";
import { Center, Text, Box, Button, Link } from "@chakra-ui/react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const FourOhFour = () => {
  return (
    <>
      <Header />
      <Center h="100vh">
        <Box textAlign="center">
          <Text fontSize="4xl" fontWeight="bold">
            ERROR 404
          </Text>
          <Text
            w={{ base: "90%", md: "100%" }}
            fontWeight="400"
            fontSize="xl"
            color="gray.600"
            textAlign="center"
          >
            We couldn't find the page you’re looking for.
          </Text>
          <Link href="/">
            <Button
              _hover={{ bg: "#22e9a0", color: "black" }}
              borderRadius="10px"
              mt="20px"
            >
              Go home
            </Button>
          </Link>

          <Link href="/app/getplan">
            <Button
              _hover={{ bg: "#22e9a0", color: "black" }}
              borderRadius="10px"
              mt="20px"
              ml="10px"
            >
              Open app
            </Button>
          </Link>
        </Box>
      </Center>
      <Footer />
    </>
  );
};

export default FourOhFour;
