import { Flex, Box, Center } from "@chakra-ui/react";
import React, { useState, useEffect, useRef } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

const PrivacyPolicy = () => {
  const [currentColor, setCurrentColor] = useState("white");
  const colors = ["#ff00ff", "#6b32e3", "#20cc55", "#22e9a0", "#ffffff"]; // Colors to sample through
  const transitionDuration = 2000;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentColor((prevColor) => {
        const currentIndex = colors.indexOf(prevColor);
        const nextIndex = (currentIndex + 1) % colors.length;
        const nextColor = colors[nextIndex];

        if (nextColor === colors[colors.length - 1]) {
          clearInterval(interval); // Stop the interval once the final color is reached
        }

        return nextColor;
      });
    }, transitionDuration);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div>
      <Header />
      <Center mt="100px">
        {" "}
        <Flex w="50%" alignItems="center" justifyContent="center">
          {" "}
          <Box
            style={{
              color: currentColor,
              transition: `color ${transitionDuration}ms ease-in-out`,
            }}
            fontSize="56px"
          >
            Privacy Policy
          </Box>
        </Flex>
      </Center>

      <Center color="gray.500" mt="100px">
        {" "}
        <Flex
          flexDir="column"
          w={{ base: "80%", md: "70%" }}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          {" "}
          <Box>1. Introduction</Box>
          <Box mt="20px">
            Welcome to DegenInsure. DegenInsure ("us," "we," or "our") operates
            the website degeninsure.com (referred to as the "Service"). This
            Privacy Policy outlines the rules and practices regarding the
            collection, protection, and disclosure of information resulting from
            your use of our Service. We utilize your data to offer and enhance
            the Service. Your use of the Service implies your acceptance of this
            policy. Unless explicitly defined in this Privacy Policy, the terms
            used herein have the same meanings as those in our Terms and
            Conditions. Our Terms and Conditions ("Terms") govern all Service
            usage and, together with this Privacy Policy, constitute our
            agreement with you.
          </Box>
          <Box mt="20px">2. Definitions</Box>
          <Box mt="20px">
            SERVICE means the degeninsure.com website operated by DegenInsure.
            PERSONAL DATA means data about a living individual who can be
            identified from those data (or from those and other information
            either in our possession or likely to come into our possession).
            USAGE DATA is data collected automatically either generated by the
            use of Service or from Service infrastructure itself (for example,
            the duration of a page visit). COOKIES are small files stored on
            your device (computer or mobile device). DATA CONTROLLER means a
            natural or legal person who (either alone or jointly or in common
            with other persons) determines the purposes for which and the manner
            in which any personal data are, or are to be, processed. For the
            purpose of this Privacy Policy, we are a Data Controller of your
            data. DATA PROCESSORS (OR SERVICE PROVIDERS) means any natural or
            legal person who processes the data on behalf of the Data
            Controller. We may use the services of various Service Providers in
            order to process your data more effectively. DATA SUBJECT is any
            living individual who is the subject of Personal Data. THE USER is
            the individual using our Service. The User corresponds to the Data
            Subject, who is the subject of Personal Data.
          </Box>
          <Box mt="20px">3. Information Collection and Use</Box>
          <Box mt="20px">
            We collect several different types of information for various
            purposes to provide and improve our Service to you.
          </Box>
          <Box mt="20px">4. Types of Data Collected</Box>
          <Box mt="20px">
            Personal Data <br /> <br /> While using our Service, we may ask you
            to provide us with certain personally identifiable information that
            can be used to contact or identify you (“Personal Data”). Personally
            identifiable information may include, but is not limited to:
            <br />
            <ul>
              <li>Name</li>
              <li>Cookies and Usage Data.</li>
            </ul>
          </Box>
          <Box mt="30px">
            Usage Data <br />
            <br />
            We may also collect information that your browser sends whenever you
            visit our Service or when you access Service by or through a mobile
            device (“Usage Data”). This Usage Data may include information such
            as your computer's Internet Protocol address (e.g. IP address),
            browser type, browser version, the pages of our Service that you
            visit, the time and date of your visit, the time spent on those
            pages, unique device identifiers and other diagnostic data. <br />{" "}
            When you access Service with a mobile device, this Usage Data may
            include information such as the type of mobile device you use, your
            mobile device unique ID, the IP address of your mobile device, your
            mobile operating system, the type of mobile Internet browser you
            use, unique device identifiers and other diagnostic data. Location
            Data We may use and store information about your location if you
            give us permission to do so (“Location Data”). <br /> We use this
            data to provide features of our Service, to improve and customize
            our Service. <br /> You can enable or disable location services when
            you use our Service at any time by way of your device settings.{" "}
            <br />
            Tracking Cookies <br />
            <br /> Data <br /> We use cookies and similar tracking technologies
            to track the activity on our Service and we hold certain
            information. <br />
            Cookies are files with a small amount of data which may include an
            anonymous unique identifier. Cookies are sent to your browser from a
            website and stored on your device. Other tracking technologies are
            also used such as beacons, tags and scripts to collect and track
            information and to improve and analyze our Service. <br />
            You can instruct your browser to refuse all cookies or to indicate
            when a cookie is being sent. However, if you do not accept cookies,
            you may not be able to use some portions of our Service. <br />{" "}
            <br />
            Examples of Cookies we use: <br />
            <ul>
              <li>
                {" "}
                Session Cookies: We use Session Cookies to operate our Service.
              </li>
              <li>
                Preference Cookies: We use Preference Cookies to remember your
                preferences and various settings.
              </li>
              <li>
                {" "}
                Security Cookies: We use Security Cookies for security purposes.
              </li>
              <li>
                {" "}
                Advertising Cookies: Advertising Cookies are used to serve you
                with advertisements that may be relevant to you and your
                interests.
              </li>
            </ul>{" "}
          </Box>
        </Flex>
      </Center>

      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
