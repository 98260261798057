import { ethers } from "ethers";

// Define a function to fetch insurance data
export const fetchInsuranceData = async (
  contractAddress,
  contractAbi,
  userAddress
) => {
  try {
    // Initialize a Web3 provider
    const provider = new ethers.providers.Web3Provider(window.ethereum);

    // Instantiate the contract using the provided contract address and ABI
    const contract = new ethers.Contract(
      contractAddress,
      contractAbi,
      provider
    );

    // Call the insurances function to retrieve insurance data
    const insurance = await contract.insurances(userAddress);

    // Return the retrieved insurance data
    return insurance;
  } catch (error) {
    /*     throw error; */
  }
};
