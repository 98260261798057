import {
  Box,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Avatar,
  HStack,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import AppNav from "../components/AppNav";
import axios from "axios";

const Yields = ({ ethPrice }) => {
  const [responseData, setResponseData] = useState([]);
  const [apyFilter, setApyFilter] = useState("");
  const [exposureSort, setExposureSort] = useState("");

  const handleApyFilterChange = (e) => {
    setApyFilter(e.target.value);
  };

  const handleExposureSortChange = (e) => {
    setExposureSort(e.target.value);
  };
  useEffect(() => {
    const fetchYields = async () => {
      const response = await axios
        .get("https://degeninsureserver.onrender.com/fetchYieldDetails")
        .then((data) => setResponseData(data?.data?.data));
    };
    fetchYields();
  }, []);

  const filteredData = responseData.filter((asset) => {
    if (apyFilter === "") return true;
    return parseFloat(asset.apy) < parseFloat(apyFilter);
  });

  const sortedData =
    exposureSort === ""
      ? filteredData
      : filteredData.sort((a, b) => {
          if (exposureSort === "single") {
            return a.exposure === "single" ? -1 : 1;
          }
          return 0;
        });

  function formatBalanceWithSuffix2(balance) {
    if (balance >= 1e6) {
      // Display in millions with 2 decimal places
      return (balance / 1e6)?.toFixed(2) + "M";
    } else if (balance >= 1e3) {
      // Display in thousands with 2 decimal places
      return (balance / 1e3)?.toFixed(2) + "K";
    } else {
      return balance?.toFixed(2); // No suffix for values less than 1,000
    }
  }

  const fetchLogo = (name) => {
    if (String(name) == "Ethereum") {
      return "https://cryptologos.cc/logos/ethereum-eth-logo.png?v=029";
    } else if (String(name) == "Solana") {
      return "https://cryptologos.cc/logos/solana-sol-logo.png?v=029";
    } else if (String(name) == "Tron") {
      return "https://cryptologos.cc/logos/tron-trx-logo.png?v=029";
    } else if (String(name) == "BSC") {
      return "https://cryptologos.cc/logos/bnb-bnb-logo.png?v=029";
    } else if (String(name) == "Arbitrum") {
      return "https://cryptologos.cc/logos/arbitrum-arb-logo.png?v=029";
    } else if (String(name) == "Bitcoin") {
      return "https://cryptologos.cc/logos/bitcoin-btc-logo.png?v=029";
    } else if (String(name) == "Polygon") {
      return "https://cryptologos.cc/logos/polygon-matic-logo.png?v=029";
    } else if (String(name) == "Cronos") {
      return "https://cryptologos.cc/logos/cronos-cro-logo.png?v=029";
    } else if (String(name) == "Filecoin") {
      return "https://cryptologos.cc/logos/filecoin-fil-logo.png?v=029";
    } else if (String(name) == "Gnosis") {
      return "https://cryptologos.cc/logos/gnosis-gno-gno-logo.png?v=029";
    } else if (String(name) == "Mixin") {
      return "https://cryptologos.cc/logos/mixin-xin-logo.png?v=029";
    } else if (String(name) == "Optimism") {
      return "https://cryptologos.cc/logos/optimism-ethereum-op-logo.png?v=029";
    } else if (String(name) == "Avalanche") {
      return "https://cryptologos.cc/logos/avalanche-avax-logo.png?v=029";
    } else if (String(name) == "Kava") {
      return "https://cryptologos.cc/logos/kava-kava-logo.png?v=029";
    } else if (String(name) == "MultiversX") {
      return "https://cryptologos.cc/logos/multiversx-egld-egld-logo.png?v=029";
    } else if (String(name) == "Algorand") {
      return "https://cryptologos.cc/logos/algorand-algo-logo.png?v=029";
    } else if (String(name) == "Polkadot") {
      return "https://cryptologos.cc/logos/polkadot-new-dot-logo.png?v=029";
    } else if (String(name) == "Defichain") {
      return "https://cryptologos.cc/logos/defichain-dfi-logo.png?v=029";
    } else if (String(name) == "Sui") {
      return "https://cryptologos.cc/logos/sui-sui-logo.png?v=029";
    } else if (String(name) == "Aptos") {
      return "https://cryptologos.cc/logos/aptos-apt-logo.png?v=029";
    } else if (String(name) == "Osmosis") {
      return "https://cryptologos.cc/logos/osmosis-osmo-logo.png?v=029";
    }
  };
  return (
    <div>
      <AppNav />
      <Flex direction={{ base: "column", md: "row" }}>
        <Box>
          <Sidebar />
        </Box>

        <Flex
          justifyContent="center" // Center horizontally
          alignItems="center" // Center vertically
          flex="1" // Take up available space
          p={{ base: "20px", md: "30px" }}
          direction="column"
          mt={{ base: "0px", md: "200px", lg: "0px" }}
        >
          {/*   <Select value={apyFilter} onChange={handleApyFilterChange}>
            <option value="">All APY</option>
            <option value="1">1%</option>
            <option value="3">3%</option>
          </Select>
          <Select value={exposureSort} onChange={handleExposureSortChange}>
            <option value="">All Exposure</option>
            <option value="single">Single</option>
            <option value="notSingle">Not Single</option>
          </Select> */}

          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Chain</Th>
                <Th>Project</Th>
                <Th>APY</Th>
                <Th>Exposure</Th>
                <Th>TVL</Th>
              </Tr>
            </Thead>
            <Tbody>
              {sortedData.map((asset, index) => (
                <Tr key={index}>
                  <Td>
                    <HStack>
                      <Avatar
                        src={fetchLogo(asset?.chain)}
                        boxSize="20px"
                        borderRadius="400px"
                        name={asset?.chain}
                        bg="white"
                      />
                      <Box>{asset?.chain}</Box>
                    </HStack>
                  </Td>
                  <Td noOfLines={1}>
                    {asset.project}
                    {/*  ({asset?.symbol}) */}
                  </Td>
                  <Td>{asset.apy}%</Td>
                  <Td>{asset.exposure}</Td>
                  <Td>${formatBalanceWithSuffix2(asset?.tvlUsd)}</Td>
                  {/* Add more table cells based on your data */}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Flex>
      </Flex>
    </div>
  );
};

export default Yields;
