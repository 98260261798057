import { Flex, Box, Center, Heading, Text, Link } from "@chakra-ui/react";
import React, { useState, useEffect, useRef } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

const TOS = () => {
  const [currentColor, setCurrentColor] = useState("white");
  const colors = ["#ff00ff", "#6b32e3", "#20cc55", "#22e9a0", "#ffffff"]; // Colors to sample through
  const transitionDuration = 2000;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentColor((prevColor) => {
        const currentIndex = colors.indexOf(prevColor);
        const nextIndex = (currentIndex + 1) % colors.length;
        const nextColor = colors[nextIndex];

        if (nextColor === colors[colors.length - 1]) {
          clearInterval(interval); // Stop the interval once the final color is reached
        }

        return nextColor;
      });
    }, transitionDuration);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div>
      <Header />
      <Center mt="100px">
        {" "}
        <Flex w="50%" alignItems="center" justifyContent="center">
          {" "}
          <Box
            style={{
              color: currentColor,
              transition: `color ${transitionDuration}ms ease-in-out`,
            }}
            fontSize="56px"
          >
            Terms of Service
          </Box>
        </Flex>
      </Center>

      <Center color="gray.500" mt="100px">
        {" "}
        <Flex
          flexDir="column"
          w={{ base: "80%", md: "70%" }}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          {" "}
          <Box p="20px" mt="20px">
            <Text fontSize="16px">
              Welcome to DegenInsure! These Terms of Service ("Terms") govern
              your use of our insurance platform and services ("Service").
              Please read these Terms carefully before using our Service.
            </Text>

            <Text fontSize="16px" mt="20px">
              By accessing or using our Service, you agree to be bound by these
              Terms. If you disagree with any part of these Terms, please do not
              use our Service.
            </Text>

            <Heading as="h2" fontSize="20px" mt="20px">
              1. Acceptance of Terms
            </Heading>

            <Text fontSize="16px" mt="10px">
              By accessing or using the Service, you agree to comply with and be
              legally bound by these Terms. If you do not agree to these Terms,
              you may not use the Service.
            </Text>

            <Heading as="h2" fontSize="20px" mt="20px">
              2. Changes to Terms
            </Heading>

            <Text fontSize="16px" mt="10px">
              DegenInsure may update or change these Terms from time to time. We
              will notify you of any changes by posting the updated Terms on our
              website. You are advised to review the Terms periodically for any
              changes. Your continued use of the Service after the changes will
              constitute your acceptance of such changes.
            </Text>

            <Heading as="h2" fontSize="20px" mt="20px">
              3. Privacy
            </Heading>

            <Text fontSize="16px" mt="10px">
              Your use of the Service is also governed by our Privacy Policy.
              Please review our{" "}
              <Link href="/privacy-policy">Privacy Policy</Link> to understand
              how we collect, use, and protect your information.
            </Text>

            <Heading as="h2" fontSize="20px" mt="20px">
              4. User Accounts
            </Heading>

            <Text fontSize="16px" mt="10px">
              When you create an account with us, you agree to provide accurate
              and complete information. You are responsible for maintaining the
              confidentiality of your account and password and for restricting
              access to your computer. You accept responsibility for all
              activities that occur under your account.
            </Text>

            <Heading as="h2" fontSize="20px" mt="20px">
              5. Limitation of Liability
            </Heading>

            <Text fontSize="16px" mt="10px">
              DegenInsure shall not be liable for any indirect, incidental,
              special, consequential, or punitive damages, or any loss of
              profits or revenues. We make no warranties or representations
              about the accuracy or completeness of the Service's content or the
              content of any websites linked to the Service.
            </Text>

            <Heading as="h2" fontSize="20px" mt="20px">
              6. Governing Law
            </Heading>

            <Text fontSize="16px" mt="10px">
              These Terms are governed by and construed in accordance with the
              laws of [Your Jurisdiction]. Any disputes relating to these Terms
              will be subject to the exclusive jurisdiction of the courts in
              [Your Jurisdiction].
            </Text>

            <Heading as="h2" fontSize="20px" mt="20px">
              7. Contact Us
            </Heading>

            <Text fontSize="16px" mt="10px">
              If you have any questions about these Terms, please contact us at{" "}
              <Link href="mailto:contact@degeninsure.com">
                contact@degeninsure.com
              </Link>
              .
            </Text>
          </Box>
        </Flex>
      </Center>

      <Footer />
    </div>
  );
};

export default TOS;
