import {
  Flex,
  Box,
  HStack,
  Image,
  Button,
  Icon,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  IconButton,
  useDisclosure,
  Link,
} from "@chakra-ui/react";
import React from "react";
import logo from "../images/degeninsure.svg";
import { FiArrowRight } from "react-icons/fi";
import { AiOutlineUser } from "react-icons/ai";
import { FiMenu } from "react-icons/fi";

const Header = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <div>
      <Flex p="30px" alignItems="center" justifyContent="space-between">
        <Box>
          <Link _hover={{ textDecoration: "none" }} href="/">
            {" "}
            <HStack>
              <Image boxSize="40px" src={logo} />
              <Box fontSize="20px">DegenInsure</Box>
            </HStack>
          </Link>
        </Box>
        <Box display={{ base: "none", md: "flex" }}>
          <HStack>
            <Link href="/app/signup">
              <Button
                borderRadius="10px"
                rightIcon={
                  <Icon as={FiArrowRight} transition="transform 0.2s" />
                }
                _hover={{
                  ".chakra-icon": { transform: "translateX(5px)" },
                  bg: "#22e9a0",
                  color: "black",
                }}
              >
                Register
              </Button>
            </Link>

            <Link href="/app/login">
              <Button
                borderRadius="10px"
                rightIcon={
                  <Icon as={AiOutlineUser} transition="transform 0.2s" />
                }
                _hover={{
                  ".chakra-icon": { transform: "translateY(-5px)" },
                  bg: "#22e9a0",
                  color: "black",
                }}
              >
                Log In
              </Button>
            </Link>
          </HStack>
        </Box>
        <IconButton
          display={{ base: "yes", md: "none" }}
          borderRadius="10px"
          onClick={onOpen}
          icon={<FiMenu />}
        />
      </Flex>

      <Drawer isOpen={isOpen} placement="top" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent bg="black">
          <DrawerCloseButton />
          <DrawerHeader></DrawerHeader>

          <DrawerBody borderRight="1px solid #181818">
            <Link href="/app/signup">
              <Button
                w="100%"
                borderRadius="10px"
                rightIcon={
                  <Icon as={FiArrowRight} transition="transform 0.2s" />
                }
                _hover={{
                  ".chakra-icon": { transform: "translateX(5px)" },
                  bg: "#22e9a0",
                  color: "black",
                }}
              >
                Register
              </Button>
            </Link>

            <Link href="/app/login">
              <Button
                mt="10px"
                w="100%"
                borderRadius="10px"
                rightIcon={
                  <Icon as={AiOutlineUser} transition="transform 0.2s" />
                }
                _hover={{
                  ".chakra-icon": { transform: "translateY(-5px)" },
                  bg: "#22e9a0",
                  color: "black",
                }}
              >
                Log In
              </Button>
            </Link>
          </DrawerBody>

          <DrawerFooter
            alignItems="flex-start"
            justifyContent="flex-start"
          ></DrawerFooter>
        </DrawerContent>
      </Drawer>
    </div>
  );
};

export default Header;
