import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Section1 from "../sections/Section1";
import Section2 from "../sections/Section2";
import Section3 from "../sections/Section3";
import Section4 from "../sections/Section4";
import Section5 from "../sections/Section5";
import Section6 from "../sections/Section6";
import Section7 from "../sections/Section7";
import Section8 from "../sections/Section8";
import Section9 from "../sections/Section9";

const Home = ({ ethPrice }) => {
  return (
    <div>
      <Header />
      <Section1 />
      <Section9 ethPrice={ethPrice} />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Section8 />
      <Section7 />

      <Footer />
    </div>
  );
};

export default Home;
