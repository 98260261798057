import React, { useState, useEffect } from "react";
import { fetchUserByAddress } from "../helpers/getUserData";
import { Box, HStack } from "@chakra-ui/react";
import { useAccount } from "wagmi";

const Greet = () => {
  const { address } = useAccount();
  const [greeting, setGreeting] = useState("Hi there");
  const [userName, setUserName] = useState("");
  const [emoji, setEmoji] = useState("");
  function greetBasedOnTime() {
    const currentTime = new Date();
    const currentHour = currentTime.getHours();

    if (currentHour >= 0 && currentHour < 12) {
      setGreeting("GM");
      setEmoji("🌞");
    } else if (currentHour >= 0 && currentHour > 12) {
      setGreeting("GN");
      setEmoji("🌝");
    }
  }

  useEffect(() => {
    greetBasedOnTime();
  }, []);

  const fetchDatas = async () => {
    const datas = await fetchUserByAddress(address);
    setUserName(datas?.name);
  };

  useEffect(() => {
    fetchDatas();
  }, [address]);

  return (
    <>
      <Box>
        <HStack>
          <Box> {greeting}</Box>
          <Box color="#22e9a0" noOfLines={1}>
            {" "}
            {userName}
          </Box>
          <Box> {emoji}</Box>
        </HStack>
      </Box>
    </>
  );
};

export default Greet;
