import React, { useState, useEffect } from "react";
import { Client } from "@covalenthq/client-sdk";
import {
  Box,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Avatar,
} from "@chakra-ui/react";
import Sidebar from "../components/Sidebar";
import AppNav from "../components/AppNav";
import { useAccount } from "wagmi";
import { ethers } from "ethers";
import axios from "axios";

const Assets = () => {
  const [netWorth, setNetWorth] = useState(0);
  function formatBalanceWithSuffix(balance, contractDecimals) {
    const num = ethers.utils.formatUnits(balance, contractDecimals);
    const numValue = parseFloat(num);

    if (numValue >= 1e6) {
      // Display in millions with 2 decimal places
      return (numValue / 1e6).toFixed(2) + "M";
    } else if (numValue >= 1e3) {
      // Display in thousands with 2 decimal places
      return (numValue / 1e3).toFixed(2) + "K";
    } else {
      return numValue.toFixed(2); // No suffix for values less than 1,000
    }
  }

  function formatBalanceWithSuffix2(balance) {
    if (balance >= 1e6) {
      // Display in millions with 2 decimal places
      return (balance / 1e6)?.toFixed(2) + "M";
    } else if (balance >= 1e3) {
      // Display in thousands with 2 decimal places
      return (balance / 1e3)?.toFixed(2) + "K";
    } else {
      return balance?.toFixed(2); // No suffix for values less than 1,000
    }
  }

  const [data, setData] = useState([]);
  const { address } = useAccount();
  const ApiServices = async () => {
    const client = new Client(process.env.REACT_APP_COVALENT_API_KEY);
    const resp = await client.BalanceService.getTokenBalancesForWalletAddress(
      "eth-mainnet",
      address,
      { noSpam: true }
    );

    setData(resp?.data?.items);
  };
  useEffect(() => {
    ApiServices();
  }, [address]);

  useEffect(() => {
    if (data) {
      const sumOfQuotes = data.reduce((total, obj) => {
        // Check if the 'quote' key exists in the current object
        if (obj.hasOwnProperty("quote")) {
          // Add the 'quote' value to the running total
          return total + obj.quote;
        }
        // If 'quote' key is missing, return the current total unchanged
        return total;
      }, 0);
      setNetWorth(formatBalanceWithSuffix2(sumOfQuotes));
    }
  }, [data]);

  return (
    <>
      {" "}
      <div>
        <AppNav />
        <Flex direction={{ base: "column", md: "row" }}>
          <Box>
            <Sidebar />
          </Box>

          <Flex
            h="80%"
            overflowY="scroll"
            justifyContent="center" // Center horizontally
            alignItems="center" // Center vertically
            flex="1" // Take up available space
            p={{ base: "20px", md: "30px" }}
            direction="column"
            mt={{ base: "0px", md: "200px", lg: "0px" }}
          >
            <Flex
              w={{ base: "100%", md: "80%" }}
              alignItems="flex-start"
              justifyContent="flex-start"
              flexDir="column"
              h={{ base: "100vh", md: "90vh" }}
            >
              <Box fontSize={{ base: "27px", md: "32px" }}>Your Assets</Box>

              <Box>
                Total Asset Value:{" "}
                <Box color="#22e9a0" as="span" display="inline-block">
                  {" "}
                  ${netWorth}
                </Box>{" "}
              </Box>

              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Logo</Th>
                    <Th>Symbol</Th>
                    <Th>Balance</Th>
                    <Th display={{ base: "none", md: "yes" }}>Value</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data?.map((e, i) => (
                    <Tr key={i}>
                      <Td>
                        <Avatar
                          src={e?.logo_url}
                          boxSize="30px"
                          borderRadius="400px"
                          name={e?.contract_name}
                        />
                      </Td>
                      <Td display={{ base: "none", md: "table-cell" }}>
                        {e?.contract_ticker_symbol}
                      </Td>
                      <Td>
                        <Flex
                          display={{ base: "flex", md: "none" }}
                          flexDir="column"
                          alignItems="flex-start"
                          justifyContent="flex-start"
                        >
                          <Box>{e?.contract_ticker_symbol}</Box>
                          <Box color="gray.500">
                            {formatBalanceWithSuffix(
                              e?.balance,
                              e?.contract_decimals
                            )}
                          </Box>
                        </Flex>
                        <Flex
                          flexDir="column"
                          alignItems="flex-start"
                          justifyContent="flex-start"
                          display={{ base: "none", md: "flex" }}
                        >
                          <Box>
                            {formatBalanceWithSuffix(
                              e?.balance,
                              e?.contract_decimals
                            )}{" "}
                            {e?.contract_ticker_symbol}
                          </Box>
                        </Flex>
                      </Td>
                      <Td>${formatBalanceWithSuffix2(e?.quote) || 0}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Flex>
          </Flex>
        </Flex>
      </div>
    </>
  );
};

export default Assets;
