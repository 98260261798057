import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom"; // Import useParams
import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  useToast,
  Progress,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Image,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Link,
} from "@chakra-ui/react";
import AppNav from "../components/AppNav";
import Sidebar from "../components/Sidebar";
import { fetchUserByAddress } from "../helpers/getUserData";
import { useAccount } from "wagmi";
import axios from "axios";
import { FaThumbsUp, FaThumbsDown } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { fetchUserById } from "../helpers/getUserById";
import { checkBalance } from "../helpers/getDGNSBalance";

function ProposalDetails() {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Go back to the previous page
  };

  const toast = useToast();
  const { address } = useAccount();
  const [balance, setBalance] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [chosenObject, setChosenObject] = useState({});
  const [chosenProp, setChosenProp] = useState("");
  const [user, setUser] = useState("");
  const [vote, setVote] = useState("");
  const [isHandling, setIsHandling] = useState(false);
  const [proposals, setProposals] = useState([]);
  const location = useLocation();
  const [userAddresses, setUserAddresses] = useState([]);
  const data = location.state?.data;

  const checkBals = async () => {
    const bal = await checkBalance(
      "0x223028738503838E89Fc5fd5b1A42F1d024d9600",
      address
    );
    setBalance(String(bal) / 10 ** 18);
  };

  useEffect(() => {
    checkBals();
  }, [address]);

  useEffect(() => {
    const targetObject = data?.proposals.find((obj) => obj._id === data?.id);
    setChosenObject(targetObject);
  }, [data]);

  const getUser = async () => {
    const users = await fetchUserByAddress(address);
    setUser(users);
  };

  useEffect(() => {
    getUser();
  }, [address]);

  const handleVote = async () => {
    setIsHandling(true);
    if (Number(balance) >= 20000) {
      try {
        const response = await axios.post(
          `https://degeninsureserver.onrender.com/api/proposals/${data?.id}/vote`,
          {
            userId: user?._id,
            vote: vote,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": process.env.REACT_APP_API_KEY,
            },
          }
        );

        toast({
          description: `You successfully voted ${vote}`,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        setIsHandling(false);
        // Add logic to handle success or display a message
      } catch (error) {
        toast({
          description: error?.response?.data?.error,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        setIsHandling(false);
      }
    } else {
      setIsHandling(false);
      onOpen();
    }
  };

  useEffect(() => {
    const fetchProposals = async () => {
      try {
        const response = await axios.get(
          "https://degeninsureserver.onrender.com/api/getproposals",
          {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": process.env.REACT_APP_API_KEY,
            },
          }
        );
        setProposals(response.data);
      } catch (error) {}
    };

    fetchProposals();
  }, []);

  useEffect(() => {
    const targetObject = proposals.find((obj) => obj._id === data?.id);
    setChosenProp(targetObject);
  }, [proposals]);

  const yesVotes = chosenProp?.votes?.filter(
    (vote) => vote.vote === "yes"
  ).length;
  const noVotes = chosenProp?.votes?.filter(
    (vote) => vote.vote === "no"
  ).length;
  const totalVotes = yesVotes + noVotes;

  useEffect(() => {
    // Function to fetch user addresses and set them to state
    const fetchUserAddresses = async () => {
      const userIds = chosenProp?.votes?.map((vote) => vote.userId) || [];
      const userAddresses = await Promise.all(
        userIds.map(async (userId) => {
          const user = await fetchUserById(userId);

          return user; // Use "Fetching..." as a placeholder
        })
      );
      setUserAddresses(userAddresses);
    };

    fetchUserAddresses(); // Call the function when the component mounts or when chosenProp changes
  }, [chosenProp]);

  console.log({ userId: user?._id, vote: vote });

  return (
    <div>
      <AppNav />
      <Flex direction={{ base: "column", md: "row" }}>
        <Box>
          <Sidebar />
        </Box>
        <Flex
          justifyContent="center" // Center horizontally
          alignItems="center" // Center vertically
          flex="1" // Take up available space
          p={{ base: "20px", md: "30px" }}
          direction="column"
        >
          <Box
            overflow="scroll"
            h="90%"
            mt={{ base: "0px", md: "200px", lg: "0px" }}
            w={{ base: "330px", md: "60%", lg: "40%" }}
          >
            <Flex
              w="100%"
              flexDir="column"
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <Button
                _hover={{ bg: "#22e9a0", color: "white" }}
                onClick={handleGoBack}
                borderRadius="10px"
                mb="30px"
              >
                Back
              </Button>
              <Box fontSize={{ base: "32px", md: "44px" }}>
                {chosenObject?.title}
              </Box>
              <Box fontSize="12px" color="gray.500">
                Voting closes by{" "}
                {new Date(chosenObject?.expiresAt).toLocaleString()}
              </Box>

              <Box fontSize="20px" mt="40px">
                Description
              </Box>

              <Box color="gray.500">{chosenObject?.description}</Box>

              <HStack w="100%" mt="40px">
                <Button
                  size="sm"
                  _hover={{ bg: "#22e9a0", color: "white" }}
                  leftIcon={<Icon as={FaThumbsUp} />}
                  isLoading={isHandling}
                  onClick={() => {
                    setVote("yes");
                    handleVote();
                  }}
                  borderRadius="10px"
                ></Button>
                <Button
                  size="sm"
                  _hover={{ bg: "red.500", color: "white" }}
                  leftIcon={<Icon as={FaThumbsDown} />}
                  isLoading={isHandling}
                  onClick={() => {
                    setVote("no");
                    handleVote();
                  }}
                  borderRadius="10px"
                ></Button>
              </HStack>

              <Box mt="50px" fontSize="20px">
                Votes
              </Box>
            </Flex>

            <div>
              <Progress
                mt="10px"
                borderRadius="2px"
                h="5px"
                value={(yesVotes / totalVotes) * 100}
                colorScheme="green"
                size="md"
                mb={2}
              />
            </div>
            <Progress
              mt="10px"
              borderRadius="2px"
              h="5px"
              value={(noVotes / totalVotes) * 100}
              colorScheme="red"
              size="md"
            />

            <Box mt="40px" fontSize="20px">
              Voters
            </Box>
            <Table variant="simple" mt={4}>
              <Thead>
                <Tr>
                  <Th>Image</Th>
                  <Th>Name</Th>
                  <Th>Address</Th>
                  <Th>Vote</Th>
                </Tr>
              </Thead>
              <Tbody>
                {chosenProp?.votes?.map((vote, index) => (
                  <Tr key={index}>
                    <Td>
                      <Image
                        src={`https://www.gravatar.com/avatar/${vote.userId}?d=identicon`}
                        alt="User Blockie"
                        boxSize="30px"
                        borderRadius="300px"
                      />
                    </Td>
                    <Td>{userAddresses[index]?.name}</Td>
                    <Td>
                      {userAddresses[index]?.ethAddress?.slice(0, 6) +
                        "..." +
                        userAddresses[index]?.ethAddress?.slice(-4)}
                    </Td>
                    <Td>{vote.vote}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </Flex>
      </Flex>

      <Modal
        size={{ base: "full", md: "xs" }}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent
          bg="black"
          border="1.5px solid #181818"
          borderRadius="15px"
        >
          <ModalHeader>Not enough tokens</ModalHeader>
          <ModalCloseButton
            _hover={{
              color: "#22e9a0",
              borderRadius: "10px",
              border: "1px solid #22e9a0",
            }}
          />
          <ModalBody>
            To create and vote on proposals, you'll need at least 20k $DGNS. You
            currently have{" "}
            <Box color="#22e9a0" as="span" display="inline-flex">
              {balance}
            </Box>{" "}
            $DGNS.
            <Link
              isExternal
              href="https://app.uniswap.org/swap?outputCurrency=0x223028738503838E89Fc5fd5b1A42F1d024d9600&chain=mainnet"
              _hover={{ textDecoration: "none" }}
            >
              {" "}
              <Button
                borderRadius="10px"
                _hover={{ bg: "#22e9a0", color: "black" }}
                w="100%"
                mt="20px"
              >
                Purchase $DGNS
              </Button>
            </Link>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default ProposalDetails;
