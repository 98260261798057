import {
  Flex,
  Box,
  Input,
  Textarea,
  Button,
  Link,
  useToast,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import AppNav from "../components/AppNav";
import Sidebar from "../components/Sidebar";
import { fetchUserByAddress } from "../helpers/getUserData";
import { ethers } from "ethers";
import { degenInsureAbi } from "../resources/degenInsureAbi.js";
import { fetchInsuranceData } from "../helpers/getUserPlans";
import moment from "moment";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import axios from "axios";
import { useAccount } from "wagmi";

const ClaimPayouts = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { address } = useAccount();
  const [addy, setAddy] = useState(
    address?.slice(0, 6) + "..." + address?.slice(-4)
  );
  const toast = useToast();
  const [coverDate, setCoverDate] = useState("");
  const [insuranceData, setInsuranceData] = useState({});
  const [coverValue, setCoverValue] = useState("");
  const [protocol, setProtocol] = useState("");
  const [date, setDate] = useState("");

  const [claimNotes, setClaimNotes] = useState("");
  const [claimAmount, setClaimAmount] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fileInput, setFileInput] = useState([]);
  const [userName, setUserName] = useState("");

  const fetchUserPlans = async () => {
    const insure = await fetchInsuranceData(
      "0xe3168795300220712f90A3E68ac111BB75215291",
      degenInsureAbi,
      address
    );
    setInsuranceData(insure);
  };

  useEffect(() => {
    fetchUserPlans();
  }, [address]);

  useEffect(() => {
    if (insuranceData && insuranceData.ethEquivalent) {
      const ethEquivalentValue = ethers.utils.formatUnits(
        insuranceData.ethEquivalent._hex,
        18
      );
      const decimalTimestamp = parseInt(insuranceData?.endTime?._hex, 16);
      const timestampMoment = moment?.unix(decimalTimestamp);
      const formattedTimestamp = timestampMoment.format("YYYY-MM-DD HH:mm:ss");

      setCoverDate(formattedTimestamp);
      setCoverValue(ethEquivalentValue);
    } else {
      setCoverValue("");
    }
  }, [insuranceData]);

  const handleFileChange = (e) => {
    // Limit the number of files to 5
    const files = Array.from(e.target.files).slice(0, 5);
    setFileInput(files);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    const data = new FormData();
    data.append("name", userName);
    data.append("ethAddress", addy === "" ? address : addy);
    data.append("date", date);
    data.append("protocol", protocol);
    data.append("claimAmount", claimAmount);
    data.append("claimNotes", claimNotes);

    for (let i = 0; i < fileInput.length; i++) {
      data.append("photos", fileInput[i]); // Append files to the "photos" field
    }

    try {
      const response = await axios.post(
        "https://degeninsureserver.onrender.com/api/getUser",
        data,
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
          },
        }
      );

      // Check the response status and handle it accordingly
      toast({
        description: "Claim uploaded successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      setIsSubmitting(false);
      onOpen();
    } catch (error) {
      setIsSubmitting(false);
      toast({
        description: "Claim upload failed",
        status: "error",
        duration: 9000,
        isClosable: true,
      });

      // Handle the error as needed
    }
  };

  const renderFilePreviews = () => {
    if (fileInput.length === 0) {
      return null; // No files selected
    }

    return (
      <Flex flexWrap="wrap" mt="10px">
        {fileInput.map((file, index) => (
          <Box key={index} m="5px">
            <img
              src={URL.createObjectURL(file)}
              alt={`Preview ${index}`}
              style={{ maxWidth: "100px" }}
            />
            <Text fontSize="sm" textAlign="center">
              {file.name}
            </Text>
          </Box>
        ))}
      </Flex>
    );
  };

  const fetchDatas = async () => {
    const datas = await fetchUserByAddress(address);
    setUserName(datas?.name);
  };

  useEffect(() => {
    fetchDatas();
  }, []);

  return (
    <div>
      <AppNav />
      <Flex direction={{ base: "column", md: "row" }}>
        <Box>
          <Sidebar />
        </Box>
        <Flex
          justifyContent="center" // Center horizontally
          alignItems="center" // Center vertically
          flex="1" // Take up available space
          p={{ base: "20px", md: "30px" }}
          direction="column"
        >
          <Box
            mt={{ base: "0px", md: "200px", lg: "0px" }}
            w={{ base: "330px", md: "60%", lg: "40%" }}
          >
            <Flex
              w="100%"
              flexDir="column"
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <Input
                mb="10px"
                onChange={(e) => {
                  setAddy(e?.target?.value);
                }}
                placeholder="Enter affected wallet address"
                focusBorderColor="#22e9a0"
                borderRadius="8px"
              />

              <Input
                placeholder="What protocol/token did you lose on?"
                focusBorderColor="#22e9a0"
                borderRadius="8px"
                onChange={(e) => setProtocol(e.target.value)}
              />

              <Input
                placeholder="When did it happen?"
                focusBorderColor="#22e9a0"
                borderRadius="8px"
                onChange={(e) => setDate(e.target.value)}
                mt="10px"
              />

              <Input
                placeholder="How much do you want to claim?"
                focusBorderColor="#22e9a0"
                borderRadius="8px"
                onChange={(e) => setClaimAmount(e.target.value)}
                mt="10px"
              />

              {Number(coverValue) === 0 ? (
                <Box fontSize="13px" mt="10px" color="gray.500">
                  You're not covered!{" "}
                  <Link href="/app/getplan">
                    <Box
                      color="#22e9a0"
                      as="span"
                      display="inline-flex"
                      alignItems="center" // Center vertically
                    >
                      Buy cover now{" "}
                      <Box
                        color="#22e9a0"
                        as="span"
                        display="inline-flex"
                        alignItems="center"
                        ml="3px"
                      >
                        <HiOutlineArrowNarrowRight />
                      </Box>
                    </Box>
                  </Link>
                </Box>
              ) : (
                <Box fontSize="13px" mt="10px" color="gray.500">
                  You're covered for{" "}
                  {insuranceData && (
                    <Box color="#22e9a0" as="span" display="inline-block">
                      {" "}
                      {coverValue}
                    </Box>
                  )}{" "}
                  ETH up to{" "}
                  <Box color="#22e9a0" as="span" display="inline-block">
                    {" "}
                    {coverDate}
                  </Box>
                </Box>
              )}

              <Textarea
                placeholder="Enter as many details as you can about the incident including links to screenshots, transaction hashes, etc. Be as detailed as possible to help with quicker claims."
                focusBorderColor="#22e9a0"
                borderRadius="8px"
                mt="10px"
                minH={{ base: "300px", md: "300px" }}
                onChange={(e) => setClaimNotes(e.target.value)}
              />
              <Input
                mt="10px"
                type="file"
                name="photos"
                accept="image/*"
                onChange={handleFileChange}
                required
                multiple
                maxFiles={5} // Limit to 5 files
                placeholder="Add photos if possible"
                borderRadius="10px"
                focusBorderColor="#22e9a0"
              />

              {/* Display the file previews */}
              <Flex flexWrap="wrap" mt="10px">
                {renderFilePreviews()}
              </Flex>

              <Button
                isDisabled={Number(coverValue) === 0 ? true : false}
                _hover={{ bg: "#22e9a0", color: "black" }}
                borderRadius="10px"
                w="100%"
                mt="10px"
                isLoading={isSubmitting}
                onClick={() => handleSubmit()}
              >
                Submit claim
              </Button>
            </Flex>
          </Box>
        </Flex>
      </Flex>

      <Modal
        size={{ base: "full", md: "md" }}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent
          bg="black"
          border="1.5px solid #181818"
          borderRadius="15px"
        >
          <ModalHeader>Now we wait!</ModalHeader>
          <ModalCloseButton
            _hover={{
              color: "#22e9a0",
              borderRadius: "10px",
              border: "1px solid #22e9a0",
            }}
          />
          <ModalBody>
            Your claim submission was successful. This means that we will now
            begin our due diligence process. We will check if all the
            information you gave us lines up with your description of the event.
            If you failed to give enough information, your response time (and
            hence payout time) will be severely delayed. Once we ascertain that
            you are telling the truth, your payout will be made. Until then, we
            wait!
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ClaimPayouts;
