import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Center,
  Heading,
  Flex,
  VStack,
  Input,
  FormControl,
  FormLabel,
  Avatar,
  Button,
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";
import Header from "../components/Header";
import Footer from "../components/Footer";

const SetUp = () => {
  const { address } = useAccount();
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);
  const [isRegistering, setIsRegistering] = useState(false);
  const toast = useToast();
  const [userName, setUserName] = useState("");
  const handleImageChange = (e) => {
    e.preventDefault(); // Prevent default behavior of the file input

    const file = e.target.files[0]; // Get the first selected file

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const imageSrc = e.target.result;
        setSelectedImage(imageSrc);
      };

      reader.readAsDataURL(file); // Read the file as a data URL
    }
  };

  const handleAvatarClick = () => {
    // Trigger the hidden input element when the avatar is clicked
    document.getElementById("fileInput").click();
  };

  const createUser = async () => {
    try {
      setIsRegistering(true);
      const formData = new FormData();
      formData.append("name", userName);
      formData.append("ethAddress", address);
      formData.append("profilePhoto", selectedImage);
      formData.append("degenPoints", "0");

      const response = await axios.post(
        "https://degeninsureserver.onrender.com/api/createUser",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_API_KEY,
          },
        }
      );

      setIsRegistering(false);
      toast({
        description: "Profile successfully created",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      navigate("/app/getplan");
    } catch (error) {
      setIsRegistering(false);
      toast({
        description: error?.response?.data?.error,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (!address) {
      navigate("/app/signup");
    }
  }, [address]);

  return (
    <div>
      <Header />
      <Center h="100vh">
        <Flex direction="column" align="center">
          <Heading mb="15px">Set up your profile</Heading>
          <Avatar
            fallbackSrc="https://bit.ly/broken-link"
            size="xl"
            src={selectedImage}
            onClick={handleAvatarClick}
            cursor="pointer"
          />

          <Input
            id="fileInput"
            type="file"
            accept="image/*" // Allow only image files
            onChange={handleImageChange}
            style={{ display: "none" }}
            // Hide the input element
          />
          <VStack mt="10px" w="100%" spacing="3px">
            <FormControl>
              <FormLabel>Your Address</FormLabel>
              <Input
                readOnly
                focusBorderColor="none"
                borderRadius="10px"
                w="100%"
                value={address}
                color="gray.500"
              />
              <FormLabel mt="10px">Username</FormLabel>
              <Input
                focusBorderColor="#22e9a0"
                borderRadius="10px"
                w="100%"
                placeholder="Enter username"
                onChange={(e) => setUserName(e?.target?.value)}
              />
            </FormControl>

            <Button
              isLoading={isRegistering}
              onClick={() => createUser()}
              mt="20px"
              w="100%"
              borderRadius="10px"
              _hover={{ bg: "#22e9a0", color: "black" }}
            >
              Sign Up
            </Button>
          </VStack>
        </Flex>
      </Center>
      <Footer />
    </div>
  );
};

export default SetUp;
