import { Flex, Box, Center } from "@chakra-ui/react";
import React, { useState, useEffect, useRef } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

const Mandatory = () => {
  const [currentColor, setCurrentColor] = useState("white");
  const colors = ["#ff00ff", "#6b32e3", "#20cc55", "#22e9a0", "#ffffff"]; // Colors to sample through
  const transitionDuration = 2000;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentColor((prevColor) => {
        const currentIndex = colors.indexOf(prevColor);
        const nextIndex = (currentIndex + 1) % colors.length;
        const nextColor = colors[nextIndex];

        if (nextColor === colors[colors.length - 1]) {
          clearInterval(interval); // Stop the interval once the final color is reached
        }

        return nextColor;
      });
    }, transitionDuration);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div>
      <Header />
      <Center mt="100px">
        {" "}
        <Flex w="50%" alignItems="center" justifyContent="center">
          {" "}
          <Box
            style={{
              color: currentColor,
              transition: `color ${transitionDuration}ms ease-in-out`,
            }}
            fontSize="56px"
          >
            Mandatory Disclaimer
          </Box>
        </Flex>
      </Center>

      <Center color="gray.500" mt="100px">
        {" "}
        <Flex
          flexDir="column"
          w={{ base: "80%", md: "70%" }}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          {" "}
          <Box>
            This disclaimer governs your interraction with the DegenInsure team
            concerning claims and disputes regarding
            <ul style={{ marginTop: "20px" }}>
              <li>Failure to pay out claims</li>
              <li>Devaluation of the $DGNS token</li>
              <li>Usage of taxes collected from trading the $DGNS token</li>
              <li>Other miscallenous disputes</li>
            </ul>
          </Box>
          <Box mt="30px" color="white">
            1. Failure to pay out claims
          </Box>
          <Box mt="10px">
            Payouts of claims are made solely on the discretion of the claims
            overseeing commitee (referred to as the "Committee"), which
            comprises members of the DegenInsure team. The Committee also
            oversees the public wallet in which claim funds are held. The
            Committee reserves the right to withhold payment of claims if it
            deems that there is insufficient evidence available to make said
            claim, or otherwise based on its own discretion. Under no
            circumstances will claims be paid out unless the claims committee
            approves the payout. Claim processing can take anywhere between an
            hour and a year, and it is left to the claims committee to give the
            final decision in whatever amount of time they deem fit.
          </Box>
          <Box mt="30px" color="white">
            2. Devaluation of the $DGNS token
          </Box>
          <Box mt="10px">
            The $DGNS token (referred to as the "Token") is a utility token
            issued by the DegenInsure team. By purchasing the token, you agree
            that
            <br />
            <ul>
              <li>you are not purchasing a security in any shape or form</li>
              <li>you have checked with local laws and it is ok to purchase</li>
              <li>
                you are not a citizen of the United States, Abkhazia,
                Afghanistan, Belarus, Crimea, Cuba, Iran, Iraq, Myanmar, North
                Korea, Northern Cyprus, Palestine, Russia, Sahrawi, Arab
                Democratic Republic, Somalia, South Ossetia, South Sudan, Sudan,
                Syria, Luhansk and Donetsk (regions in Ukraine) or Yemen.
              </li>
              <li>
                trading taxes collected by DegenInsure on the trading of the
                $DGNS token are solely the property of the DegenInsure team and
                no obligations are placed upon the use of said taxes for either
                marketing, development or otherwise.
              </li>
              <li>
                marketing material released by DegenInsure is not a
                recommendation to use the platform or purchase the $DGNS token.
              </li>
            </ul>
            <br />
            The $DGNS token will be issued as a freely traded token on the
            Ethereum blockchain. By purchasing the Token, you wholly agree to
            not hold DegenInsure liable for any positive or negative changes in
            the price of the Token. Losses or gains incurred are solely your
            responsibility, and there is no obligation by the DegenInsure team
            to uphold or manage the price of the Token.
          </Box>
          <Box mt="30px" color="white">
            3. Usage of taxes collected from trading the $DGNS token
          </Box>
          <Box mt="10px">
            Taxes refer to the amount subtracted from the total value traded
            when a user goes to purchase or sell the $DGNS token. This
            subtracted value goes to wallets controlled by the DegenInsure team,
            and the usage of these taxes is not defined in any shape or form.
            Usage of taxes is solely determined by the DegenInsure team, and
            terms such as 'Marketing Wallet' and 'Development Wallet' do not
            imply that the funds in those wallets will wholly be used for
            Marketing and Development activities respectively.
          </Box>
          <Box mt="30px" color="white">
            3. Other miscallenous disputes
          </Box>
          <Box mt="10px">
            Kindly send any disputes you may have regarding usage of tax funds
            or payout claims to{" "}
            <Box as="span" display="inline-block" color="#22e9a0">
              disputes@degeninsure.com
            </Box>{" "}
            and a representative will contact you in 24 hours or less. Disputes
            sent to this email address will be settled solely by the Committee,
            and by using DegenInsure, its platform or purchasing its token, you
            waive your rights to having any disputes arising from any of these
            actions settled in an official court of law.
          </Box>
        </Flex>
      </Center>

      <Footer />
    </div>
  );
};

export default Mandatory;
