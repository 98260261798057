import { ethers } from "ethers";

// Ethereum provider URL
const providerUrl = "https://rpc.ankr.com/eth"; // Replace with your provider URL

// ERC-20 contract address and ABI (replace with your token's details)
const contractABI = [
  // Standard ERC-20 ABI (balanceOf function)
  {
    constant: true,
    inputs: [{ name: "_owner", type: "address" }],
    name: "balanceOf",
    outputs: [{ name: "balance", type: "uint256" }],
    type: "function",
  },
];

export async function checkBalance(contractAddress, userAddress) {
  try {
    // Connect to the Ethereum provider directly
    const provider = new ethers.providers.JsonRpcProvider(providerUrl);

    // Connect to the ERC-20 contract using the provided provider
    const erc20Contract = new ethers.Contract(
      contractAddress,
      contractABI,
      provider
    );

    // Call the balanceOf function to get the balance
    const userBalance = await erc20Contract.balanceOf(userAddress);

    return userBalance;
  } catch (error) {
    console.error("Error checking balance:", error);
  }
}
