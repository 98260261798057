import {
  Box,
  Divider,
  Flex,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import AppNav from "../components/AppNav";
import Sidebar from "../components/Sidebar";
import moment from "moment";
import { AnimatePresence, motion } from "framer-motion";

import { useAccount } from "wagmi";
import CreateProposal from "./CreateProposal";
import AllProposals from "./AllProposals";

const Vote = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  const { address } = useAccount();

  return (
    <div>
      <AppNav />
      <Flex direction={{ base: "column", md: "row" }}>
        <Box>
          <Sidebar />
        </Box>
        <Flex
          justifyContent="center" // Center horizontally
          alignItems="center" // Center vertically
          flex="1" // Take up available space
          p={{ base: "20px", md: "30px" }}
          direction="column"
        >
          <Box
            mt={{ base: "0px", md: "200px", lg: "0px" }}
            w={{ base: "330px", md: "60%", lg: "40%" }}
          >
            <Tabs minH="500px" variant="unstyled">
              <TabList
                p="8px"
                border="1.5px solid #181818"
                borderRadius="13px"
                w="320px"
              >
                <Tab
                  borderRadius="10px"
                  _selected={{
                    color: "white",
                    bg: "#22e9a0",
                    borderRadius: "10px",
                  }}
                >
                  All proposals
                </Tab>
                <Tab
                  borderRadius="10px"
                  _selected={{
                    color: "white",
                    bg: "#22e9a0",
                    borderRadius: "10px",
                  }}
                >
                  Create a proposal
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <AllProposals />
                </TabPanel>
                <TabPanel w="320px">
                  <CreateProposal />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Flex>
      </Flex>
    </div>
  );
};

export default Vote;
