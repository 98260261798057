import React, { useEffect, useState } from "react";
import { Box, Text, Progress, Avatar, Flex } from "@chakra-ui/react";

const Countdown = ({ durationInSeconds, fromTokenImage, toTokenImage }) => {
  const [timeLeft, setTimeLeft] = useState(durationInSeconds);

  useEffect(() => {
    if (timeLeft <= 0) return;

    const interval = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [timeLeft]);

  const percentage = ((durationInSeconds - timeLeft) / durationInSeconds) * 100;

  return (
    <Box>
      <Text mb="300px" fontSize={{ base: "40px", md: "60px" }}>
        {/* {Math.floor(timeLeft / 3600)}h */}{" "}
        {Math.floor((timeLeft % 3600) / 60)}m {timeLeft % 60}s
      </Text>

      {/*  <Flex alignItems="center" justifyContent="center" w="100%">
        <Avatar src={fromTokenImage} />
        <Avatar src={toTokenImage} />
      </Flex> */}

      <Progress
        w={{ base: "100%", md: "80%" }}
        borderRadius="5px"
        h="3px"
        value={percentage}
        colorScheme="teal"
        marginTop={2}
      />
    </Box>
  );
};

export default Countdown;
