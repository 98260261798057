import {
  Flex,
  Box,
  HStack,
  useToast,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Image,
  ModalCloseButton,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { BiUser } from "react-icons/bi";
import { FaTelegram, FaTwitter } from "react-icons/fa";
import { IoDocumentSharp } from "react-icons/io5";
import Greet from "./Greet";
import { useAccount } from "wagmi";
import { useNavigate } from "react-router-dom";
import { fetchUserByAddress } from "../helpers/getUserData";
import { ethers } from "ethers";
import BottomCircle from "./BottomCircle";

const AppNav = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentChain, setCurrentChain] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const SupportedChains = {
    "0x1": "Ethereum",
    "0x2105": "Base Chain",
    "0x13881": "Mumbai",
  };

  useEffect(() => {
    async function checkChain() {
      if (window.ethereum) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        try {
          const network = await provider.getNetwork();
          setCurrentChain("0x" + network.chainId.toString(16));

          if (!String(SupportedChains[network.chainId])) {
            setIsModalOpen(true);
          }
        } catch (error) {}
      }
    }

    checkChain();

    window.ethereum.on("chainChanged", async (newChainId) => {
      setCurrentChain(newChainId);
      if (!SupportedChains[newChainId]) {
        setIsModalOpen(true);
      } else {
        setIsModalOpen(false);
      }
    });
  }, []);

  const [info, setInfo] = useState("");
  const navigate = useNavigate();
  const { address } = useAccount();
  const toast = useToast();

  const formattedAddress = address?.toLowerCase()?.startsWith("0x")
    ? address?.toLowerCase()
    : `0x${address?.toLowerCase()}`;

  // Split the address into three parts: beginning, middle, and end
  const addressParts = [
    formattedAddress.substring(0, 5),
    "...",
    formattedAddress.substring(formattedAddress?.length - 4),
  ];

  // Combine the parts with line breaks for readability
  const formattedAddressWithEllipsis = addressParts?.join("\n");

  const handleCopyToClipboard = () => {
    // Ensure the address is in lowercase and has "0x" at the beginning
    const formattedAddress = address?.toLowerCase()?.startsWith("0x")
      ? address?.toLowerCase()
      : `0x${address?.toLowerCase()}`;

    // Copy the formatted address to the clipboard
    navigator.clipboard
      .writeText(formattedAddress)
      .then(() => {
        toast({
          description: "Address copied",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((error) => {});
  };

  useEffect(() => {
    // Check if the `address` variable is undefined
    if (!address) {
      // Use navigate to redirect to `/app/login`
      navigate("/app/login");
    }
  }, [address]);

  const fetchInfo = async () => {
    const infos = await fetchUserByAddress(address);
    setInfo(infos?.name);
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  useEffect(() => {
    // Check if the `address` variable is undefined
    if (info === undefined) {
      // Use navigate to redirect to `/app/login`
      navigate("/app/signup");
    }
  }, [info]);
  const connectToChain = async (chainId) => {
    if (window.ethereum) {
      setIsLoading(true);
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: chainId }],
        });
      } catch (error) {
        setIsLoading(false);
      }
      setIsLoading(false);
    }
  };

  return (
    <>
      <BottomCircle />
      <Flex
        borderBottom="1.5px solid #181818"
        p="10px"
        w="100%"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display={{ base: "none", md: "flex" }}>
          <HStack>
            <IconButton
              size="xs"
              borderRadius="8px"
              icon={<FaTwitter />}
              _hover={{ border: "1px solid #22e9a0", color: "#22e9a0" }}
            />
            <IconButton
              size="xs"
              borderRadius="8px"
              icon={<FaTelegram />}
              _hover={{ border: "1px solid #22e9a0", color: "#22e9a0" }}
            />
            <IconButton
              size="xs"
              borderRadius="8px"
              icon={<IoDocumentSharp />}
              _hover={{ border: "1px solid #22e9a0", color: "#22e9a0" }}
            />
          </HStack>
        </Box>
        <Greet />
        <Box
          /*   display={{ base: "none", md: "flex" }} */
          onClick={handleCopyToClipboard}
        >
          <HStack _hover={{ cursor: "pointer", color: "#22e9a0" }}>
            <Box>
              <BiUser />
            </Box>
            <Box>{formattedAddressWithEllipsis}</Box>
          </HStack>
        </Box>
      </Flex>

      {isModalOpen && (
        <Modal
          size={{ base: "full", md: "xs" }}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          isCentered
        >
          <ModalOverlay />
          <ModalContent
            bg="black"
            border="1.5px solid #181818"
            borderRadius="20px"
          >
            <ModalHeader>
              <ModalCloseButton
                _hover={{
                  color: "#22e9a0",
                  borderRadius: "10px",
                  border: "1px solid #22e9a0",
                }}
              />
              <Flex
                mt="30px"
                w="100%"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box>Wrong Network</Box>
                <Flex alignItems="center" justifyContent="center">
                  <Box>
                    {" "}
                    <Image
                      boxSize="30px"
                      borderRadius="8px"
                      src="https://icons.llamao.fi/icons/chains/rsz_base.jpg"
                    />
                  </Box>

                  <Box ml="-10px">
                    {" "}
                    <Image
                      boxSize="30px"
                      borderRadius="8px"
                      src="https://cdn.via.exchange/networks/Ethereum.svg"
                    />
                  </Box>
                </Flex>
              </Flex>
            </ModalHeader>
            <ModalBody>
              You are currently connected to an unsupported chain and your
              experience will be severly limited. Please select a supported
              chain to continue.
              <Flex
                mt="20px"
                flexDir="column"
                alignItems="center"
                justifyContent="center"
                w="100%"
              >
                {Object.entries(SupportedChains).map(([chainId, chainName]) => (
                  <Button
                    isLoading={isLoading}
                    mt="10px"
                    w="100%"
                    key={chainId}
                    _hover={{ bg: "#22e9a0", color: "black" }}
                    borderRadius={{ base: "10px", md: "8px" }}
                    onClick={() => connectToChain(chainId)}
                    size={{ base: "md", md: "sm" }}
                  >
                    Connect to {chainName}
                  </Button>
                ))}
              </Flex>
            </ModalBody>

            <ModalFooter></ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default AppNav;
