import React, { useState, useEffect, useRef } from "react";
import { Flex, Box, Grid, GridItem, Icon } from "@chakra-ui/react";
import { useInView } from "framer-motion";
import { BsFillLightningChargeFill } from "react-icons/bs";
import { TbMoneybag } from "react-icons/tb";
import { PiGavelFill } from "react-icons/pi";

const Section5 = () => {
  const data = [
    {
      title: "Fast claims",
      text: "Claim reviews are handled by a dedicated team to ensure industry leading response times",
      icon: BsFillLightningChargeFill,
    },
    {
      title: "Instant Payouts",
      text: "Once claims are approved, payouts are made instantly by the contract.",
      icon: TbMoneybag,
    },
    {
      title: "Dispute Handling",
      text: "Community members are involved in the process in the form of dispute handling.",
      icon: PiGavelFill,
    },
  ];
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [currentColor, setCurrentColor] = useState("white");
  const colors = ["#ff00ff", "#6b32e3", "#20cc55", "#22e9a0"]; // Colors to sample through
  const transitionDuration = 2000;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentColor((prevColor) => {
        const currentIndex = colors.indexOf(prevColor);
        const nextIndex = (currentIndex + 1) % colors.length;
        const nextColor = colors[nextIndex];

        if (nextColor === colors[colors.length - 1]) {
          clearInterval(interval); // Stop the interval once the final color is reached
        }

        return nextColor;
      });
    }, transitionDuration);

    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <div>
      <Flex
        flexDir="column"
        mt="100px"
        w="100%"
        alignItems="center"
        justifyContent="center"
        ref={ref}
      >
        <Box fontSize="30px" textAlign="center">
          The only insurance platform for{" "}
          <Box
            style={{
              color: currentColor,
              transition: `color ${transitionDuration}ms ease-in-out`,
            }}
            as="span"
            display="inline-block"
          >
            crypto
          </Box>{" "}
          degens.
        </Box>

        <Box
          mt="15px"
          color="gray.500"
          w={{ base: "80%", md: "70%" }}
          textAlign="center"
        >
          DegenInsure is specially crafted for crypto users that like to take
          risks. Crypto is currently the wild west of finance, and it's
          important to be protected from rugs, bad actors and rapid asset
          devaluation.
        </Box>

        <Grid
          mt="40px"
          templateRows={{ base: "repeat(3,1fr)", md: "repeat(1,1fr)" }}
          templateColumns={{ base: "repeat(1,1fr)", md: "repeat(3,1fr)" }}
          gap={10}
        >
          {data?.map((e, i) => {
            return (
              <GridItem
                w={{ base: "300px", md: "250px", lg: "300px" }}
                h="350px"
                borderRadius="20px"
                p="20px"
                background="linear-gradient(to bottom, #22e9a0, black)"
                style={{
                  transform: isInView ? "none" : "translateY(200px)",
                  opacity: isInView ? 1 : 0,
                  transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                }}
              >
                <Box
                  w="50px"
                  h="50px"
                  borderRadius="50%"
                  backgroundColor="white"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  mt="20px"
                >
                  <Icon as={e?.icon} color="#22e9a0" fontSize="32px" />
                </Box>
                <Box mt="10px" fontSize="25px">
                  {e?.title}
                </Box>
                <Box mt="20px" fontWeight="500">
                  {e?.text}
                </Box>
              </GridItem>
            );
          })}
        </Grid>
      </Flex>
    </div>
  );
};

export default Section5;
