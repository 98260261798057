import { ethers } from "ethers";

export async function getFromTokenBalance(userAddress, tokenAddress) {
  let provider = new ethers.providers.JsonRpcProvider(
    "https://rpc.ankr.com/eth"
  );

  if (tokenAddress === "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE") {
    // Fetch Ether (ETH) balance
    const balanceWei = await provider.getBalance(userAddress);
    return balanceWei;
  } else {
    // Fetch token balance
    const tokenContract = new ethers.Contract(
      tokenAddress,
      ["function balanceOf(address) view returns (uint256)"],
      provider
    );
    const balance = await tokenContract.balanceOf(userAddress);
    return balance.toString();
  }
}
