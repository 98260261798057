import {
  useDisclosure,
  Circle,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Image,
  Alert,
  AlertIcon,
  Grid,
  GridItem,
  Icon,
  Box,
  Link,
  Button,
  Flex,
} from "@chakra-ui/react";
import logo from "../images/degeninsure.svg";
import { AiOutlineSwap } from "react-icons/ai";
import { PiBridge } from "react-icons/pi";
import { BsPeople, BsBank2 } from "react-icons/bs";
import { BiLockAlt } from "react-icons/bi";
import { TbMoneybag } from "react-icons/tb";

function BottomCircle() {
  const data = [
    {
      title: "DEX",
      link: "/app/trade/0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE/1/0x223028738503838E89Fc5fd5b1A42F1d024d9600/",
      icon: AiOutlineSwap,
      isDisabled: false,
    },
    {
      title: "Bridge",
      link: "",
      icon: PiBridge,
      isDisabled: true,
    },
    {
      title: "P2P",
      link: "",
      icon: BsPeople,
      isDisabled: true,
    },
    {
      title: "Locker",
      link: "",
      icon: BiLockAlt,
      isDisabled: true,
    },
    {
      title: "Lend",
      link: "",
      icon: BsBank2,
      isDisabled: true,
    },

    {
      title: "Yield",
      link: "/app/yields",
      icon: TbMoneybag,
      isDisabled: false,
    },
  ];
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      {" "}
      <Circle
        bg="#111215"
        color="white"
        w="40px"
        h="40px"
        borderRadius="full"
        position="fixed"
        right="20px"
        boxShadow="md"
        cursor="pointer"
        onClick={onOpen}
        bottom={{ base: "60px", md: "20px" }}
        border="0.1px solid #22e9a0"
        animation="upAndDown 3.5s cubic-bezier(.25,.46,.45,.94) infinite"
        style={{ willChange: "bottom" }}
        p="5px"
      >
        <style>
          {`
          @keyframes upAndDown {
            0%, 100% {
              bottom: 60px;
            }
            50% {
              bottom: 40px;
            }
          }
        `}
        </style>
        <Image src={logo} boxSize="80%" />
      </Circle>
      <Modal
        w="100%"
        size={{ base: "full", md: "xs" }}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent
          border="1.5px solid #181818"
          bg={{ base: "black", md: "black" }}
          borderRadius="12px"
          h="580px"
          w={{ base: "330px", md: "500px" }}
        >
          <ModalHeader>DegenInsure Ecosystem</ModalHeader>
          <ModalCloseButton
            _hover={{
              color: "#22e9a0",
              borderRadius: "10px",
              border: "1px solid #22e9a0",
            }}
            borderRadius="10px"
          />
          <ModalBody overflow="scroll" p="10px">
            <Alert borderRadius="10px" status="success">
              <AlertIcon />
              All apps in this suite are fully insured. Read our docs for more
              info.
            </Alert>

            <Grid
              mt="40px"
              templateRows={{ base: "repeat(3,1fr)", md: "repeat(3,1fr)" }}
              templateColumns={{ base: "repeat(2,1fr)", md: "repeat(2,1fr)" }}
              gap={5}
            >
              {data?.map((e, i) => {
                return (
                  <Link _hover={{ textDecoration: "none" }} href={e?.link}>
                    <Button
                      _hover={{
                        background:
                          "linear-gradient(to bottom, #22e9a0, black)",
                      }}
                      isDisabled={e?.isDisabled}
                      w="130px"
                      h="100px"
                      borderRadius="10px"
                      p="20px"
                      background="linear-gradient(to bottom, #22e9a0, black)"
                    >
                      <GridItem>
                        <Flex
                          flexDir="column"
                          w="100%"
                          alignItems="flex-start"
                          justifyContent="flex-start"
                        >
                          <Box color="white" w="30px" h="30px">
                            <Icon as={e?.icon} fontSize="23px" />
                          </Box>
                          <Box mt="10px" fontSize="15px">
                            {e?.title}
                          </Box>
                        </Flex>
                      </GridItem>
                    </Button>
                  </Link>
                );
              })}
            </Grid>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default BottomCircle;
