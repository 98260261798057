import React, { useState, useEffect } from "react";
import ConnectButton from "../components/ConnectButton";
import {
  Center,
  Box,
  Link,
  Flex,
  Image,
  Checkbox,
  HStack,
  Divider,
} from "@chakra-ui/react";
import logo from "../images/degeninsure.svg";
import Granim from "granim";
import "./SignUp.css";
import { useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";

const Login = () => {
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const { address } = useAccount();

  const navigate = useNavigate();

  useEffect(() => {
    new Granim({
      element: "#logo-canvas",
      direction: "top-bottom",
      opacity: [1, 1],
      states: {
        "default-state": {
          gradients: [
            ["#000000", "#000000"],
            ["#22e9a0", "#000000"],
            ["#000000", "#000000"],
            ["#000000", "#000000"],
          ],
          transitionSpeed: 5000,
        },
      },
    });
  }, []);

  useEffect(() => {
    if (address) {
      navigate("/app/getplan");
    }
  }, [address]);

  return (
    <>
      <>
        <div className="stuff">
          <canvas id="logo-canvas"></canvas>
          <div className="center-content">
            <Flex
              w="100%"
              alignItems="flex-start"
              justifyContent="flex-start"
              p="25px"
            >
              <HStack>
                <Box>
                  {" "}
                  <Image boxSize="35px" src={logo} />
                </Box>
                <Box fontSize="20px"> DegenInsure</Box>
              </HStack>
            </Flex>
            <Center w="100%" color="white" h="100vh">
              <Flex
                direction={{ base: "column", md: "row" }}
                w={{ base: "80%", md: "40%" }}
                alignItems="center"
                justifyContent="center"
              >
                <Box w={{ base: "100%", md: "50%" }}>
                  <Flex
                    flexDir="column"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    w="100%"
                  >
                    <Box>
                      <Box fontSize={{ base: "27px", md: "32px" }}>
                        Welcome back😊
                      </Box>
                    </Box>

                    <Box mt="20px" w="100%">
                      <ConnectButton isDisabledd={isChecked} />
                    </Box>

                    <Box mt="20px" fontSize="12px">
                      <HStack>
                        <Checkbox
                          isChecked={isChecked}
                          onChange={handleCheckboxChange}
                          colorScheme="green"
                          borderRadius="10px"
                        />
                        <Box>
                          I agree to the{" "}
                          <Link href="/tos">
                            <Box
                              color="#22e9a0"
                              display="inline-block"
                              as="span"
                            >
                              {" "}
                              Terms and Conditions
                            </Box>
                          </Link>{" "}
                          ,{" "}
                          <Link href="/privacy">
                            <Box
                              color="#22e9a0"
                              display="inline-block"
                              as="span"
                            >
                              {" "}
                              Privacy Policy
                            </Box>
                          </Link>{" "}
                          and{" "}
                          <Link href="/disclaimer">
                            <Box
                              color="#22e9a0"
                              display="inline-block"
                              as="span"
                            >
                              {" "}
                              Mandatory Disclaimer
                            </Box>
                          </Link>
                          .{" "}
                        </Box>
                      </HStack>
                    </Box>

                    <Divider mt=" 20px" />
                    <Flex
                      fontSize="15px"
                      mt="20px"
                      w="100%"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Box>No account?</Box>
                      <Link _hover={{ color: "#22e9a0" }} href="/app/signup">
                        {" "}
                        <Box>Sign up</Box>
                      </Link>
                    </Flex>
                  </Flex>
                </Box>
              </Flex>
            </Center>
          </div>
        </div>
      </>
    </>
  );
};

export default Login;
