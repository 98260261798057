import axios from "axios";
export const fetchTokens = async () => {
  try {
    // Construct the full URL for the server endpoint
    const endpoint = "https://degeninsureserver.onrender.com/api/tokenList";

    // Make the GET request to the server
    const response = await axios.get(endpoint, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    });

    // Return the data received from the server
    return response.data.data;
  } catch (error) {
    // Handle errors here or throw the error for handling in the calling code
    console.error("Error fetching token list:", error);
  }
};
