import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Heading, VStack } from "@chakra-ui/react";
import axios from "axios";

function AllProposals() {
  const [proposals, setProposals] = useState([]);
  const [id, setId] = useState("");

  useEffect(() => {
    const fetchProposals = async () => {
      try {
        const response = await axios.get(
          "https://degeninsureserver.onrender.com/api/getproposals",
          {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": process.env.REACT_APP_API_KEY,
            },
          }
        );
        setProposals(response.data);
      } catch (error) {}
    };

    fetchProposals();
  }, []);

  const handleMouseEnter = (proposalId) => {
    setId(proposalId);
  };

  return (
    <VStack align="stretch">
      {proposals.map((proposal) => (
        <Link
          state={{ data: { id, proposals } }}
          key={proposal._id}
          to={`/app/proposal/${id}`}
        >
          <Box
            borderRadius="12px"
            _hover={{ border: "1px solid #22e9a0" }}
            onMouseEnter={() => handleMouseEnter(proposal._id)} // Set id on hover
            borderWidth="1px"
            p="4"
          >
            <Heading color="#22e9a0" size="md">
              {proposal.title || "No Title"}
            </Heading>
            <Box mt="10px">{proposal.description}</Box>
            <Box mt="10px" color="gray.500">
              Expires At: {new Date(proposal.expiresAt).toLocaleString()}
            </Box>
          </Box>
        </Link>
      ))}
    </VStack>
  );
}

export default AllProposals;
