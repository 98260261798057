import React, { useState, useEffect, useRef } from "react";
import { Flex, Box, Link, Button } from "@chakra-ui/react";
import { useInView } from "framer-motion";

const Section7 = () => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [currentColor, setCurrentColor] = useState("white");
  const colors = ["#ff00ff", "#6b32e3", "#20cc55", "#22e9a0"]; // Colors to sample through
  const transitionDuration = 2000;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentColor((prevColor) => {
        const currentIndex = colors.indexOf(prevColor);
        const nextIndex = (currentIndex + 1) % colors.length;
        const nextColor = colors[nextIndex];

        if (nextColor === colors[colors.length - 1]) {
          clearInterval(interval); // Stop the interval once the final color is reached
        }

        return nextColor;
      });
    }, transitionDuration);

    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <div>
      <Flex mt="100px" w="100%" alignItems="center" justifyContent="center">
        <Box
          style={{
            backgroundColor: currentColor,
            transition: `color ${transitionDuration}ms ease-in-out`,
          }}
          borderRadius="30px"
          p="50px"
          w={{ base: "80%", md: "70%" }}
          h={{ base: "360px", md: "300px" }}
          textAlign="center"
        >
          <Box fontSize={{ base: "25px", md: "30px" }}>You're still here?</Box>
          <Box mt="20px">
            Someone just got hacked while you were reading the content of this
            website. They're going to wish they had DegenInsure cover. What
            about you?{" "}
          </Box>
          <Link href="/app/getplan">
            <Button
              mt="20px "
              borderRadius="10px"
              _hover={{ bg: "black", color: "white" }}
            >
              Get a plan
            </Button>
          </Link>
        </Box>
      </Flex>
    </div>
  );
};

export default Section7;
