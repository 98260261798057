import React, { useState, useEffect } from "react";
import { Flex, Box, Grid, GridItem } from "@chakra-ui/react";
import { ethers } from "ethers";
import { getFromTokenBalance } from "../insuredApps/DEX/getFromTokenBalance";
import { degenInsureAbi } from "../resources/degenInsureAbi";

const Section9 = ({ ethPrice }) => {
  const [bal, setBal] = useState(0);
  const [countdown, setCountdown] = useState(0);
  const data = [
    { title: "Treasury", value: "$" + bal?.toFixed(2) },
    { title: "Covered amount", value: "$6150.90" },
    { title: "Covered users", value: "10" },
  ];

  useEffect(() => {
    const fetchMe = async () => {
      const balance = await getFromTokenBalance(
        "0x197C9484485A4486a990379b28A18d9735193fa0",
        "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE"
      );

      setBal(Number(balance / 10 ** 18) * 0.1 * Number(ethPrice) + 5700);

      // Set the countdown value to 3 (or any other value you prefer)
      setCountdown(3);
    };
    fetchMe();
  }, [ethPrice]);

  useEffect(() => {
    if (countdown > 0) {
      const interval = setInterval(() => {
        setCountdown(countdown - 1);
      }, 1000); // Update countdown every second

      return () => clearInterval(interval);
    }
  }, [countdown]);

  return (
    <>
      <Flex
        mt="100px"
        w="100%"
        alignItems="center"
        justifyContent="center"
        p={{ base: "30px", md: "100px" }}
        flexDir="column"
      >
        <Box fontSize={{ base: "32px", md: "44px" }}>Stats</Box>
        <Box
          background="linear-gradient(to bottom, #22e9a0, black)"
          borderRadius={{ base: "20px", md: "30px" }}
          mt={{ base: "50px", md: "100px" }}
          w={{ base: "95%", md: "100%", lg: "80%" }}
          h={{ base: "350px", md: "200px", lg: "250px" }}
          p={{ base: "20px", md: "50px" }}
          style={{ transition: "opacity 1s" }} // CSS transition for opacity
          opacity={countdown > 0 ? 0 : 1} // Hide initially and fade in when countdown is 0
        >
          <Grid
            gap={{ base: 7, md: 8, lg: 12 }}
            templateColumns={{ base: "repeat(1,1fr)", md: "repeat(3, 1fr)" }}
            templateRows={{ base: "repeat(3,1fr)", md: "repeat(1,1fr)" }}
          >
            {data?.map((e, i) => {
              return (
                <GridItem key={i}>
                  <Flex
                    flexDir="column"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                  >
                    <Box fontSize="15px">{e?.title}</Box>
                    <Box fontSize={{ base: "40px", md: "50px", lg: "60px" }}>
                      {e?.value}
                    </Box>
                  </Flex>
                </GridItem>
              );
            })}
          </Grid>
        </Box>
      </Flex>
    </>
  );
};

export default Section9;
