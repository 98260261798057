import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  Image,
  HStack,
} from "@chakra-ui/react";
import axios from "axios";
import Header from "../components/Header.jsx";
import Footer from "../components/Footer.jsx";

const Leaderboard = () => {
  const [userData, setUserData] = useState([]);
  //for anyone reading our code, first of all, not cool lol, please don't copy it. Second, these usernames are test usernames, and are used to test new features added to the app. We're not scamming anyone here, so before you write your bogus thread that no one will read, make sure to read and understand (and hopefully include) this important detail. Thanks🫡. Now bugger off.
  const [hiddenUsernames] = useState([
    "ebuka123",
    "trreesss",
    "ra-ra-rasputin",
    "21savage",
    "ebuski",
    "Raspitun",
    "phynofino",
    "agamaturuchineke",
    "ebukatheboy",
    "unleash!",
    "onaspaceship",
    "meatpie",
    "test2",
    "burnaboyy",
  ]);

  useEffect(() => {
    const fetchLeaderboard = async () => {
      try {
        const response = await axios.get(
          "https://degeninsureserver.onrender.com/api/getUsers",
          {
            headers: {
              "x-api-key": process.env.REACT_APP_API_KEY,
            },
          }
        );
        setUserData(response.data); // Set the data correctly
      } catch (error) {
        console.error("Error fetching leaderboard:", error);
      }
    };

    fetchLeaderboard();
  }, []); // The empty dependency array makes this effect run once when the component mounts.

  // Sort the data by degenPoints in descending order
  const sortedData = userData.sort((a, b) => b.degenPoints - a.degenPoints);

  // Filter out users with hidden usernames
  const filteredData = sortedData.filter(
    (user) => !hiddenUsernames.includes(user.name)
  );

  return (
    <Box>
      <Header />
      <Flex w="100%" alignItems="center" justifyContent="center">
        <Table w={{ base: "90%", md: "90%", lg: "70%" }} variant="simple">
          <Thead>
            <Tr>
              <Th>Username</Th>
              <Th isNumeric>Degen Points</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredData.map((user, i) => (
              <Tr key={user.id}>
                <Td>
                  <HStack>
                    <Box>
                      <Image
                        src={`https://www.gravatar.com/avatar/${user.name}?d=identicon`}
                        alt="User Blockie"
                        boxSize="30px"
                        borderRadius="300px"
                      />
                    </Box>
                    <Box>{user.name}</Box>
                  </HStack>
                </Td>
                <Td isNumeric>{user.degenPoints || 0}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Flex>
      <Footer />
    </Box>
  );
};

export default Leaderboard;
