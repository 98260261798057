import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Link,
  Textarea,
  Box,
} from "@chakra-ui/react";
import axios from "axios";
import { useAccount } from "wagmi";
import { checkBalance } from "../helpers/getDGNSBalance";

function CreateProposal() {
  const { address } = useAccount();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [expiresAt, setExpiresAt] = useState("");
  const toast = useToast();
  const [balance, setBalance] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleSubmit = async (e) => {
    setIsSubmitting(true);
    e.preventDefault();
    if (Number(balance) >= 20000) {
      try {
        const response = await axios.post(
          "https://degeninsureserver.onrender.com/api/submitproposal",
          {
            title,
            description,
            expiresAt,
            address,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": process.env.REACT_APP_API_KEY,
            },
          }
        );

        toast({
          description: "Proposal submitted successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        setIsSubmitting(false);
        // Add logic to handle success or redirection
      } catch (error) {
        toast({
          description: "Proposal submission failed",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        setIsSubmitting(false);
      }
    } else {
      onOpen();
      setIsSubmitting(false);
    }
  };

  const checkBals = async () => {
    const bal = await checkBalance(
      "0x223028738503838E89Fc5fd5b1A42F1d024d9600",
      address,
    );
    setBalance(String(bal) / 10 ** 18);
  };

  useEffect(() => {
    checkBals();
  }, [address]);

  return (
    <>
      <VStack align="stretch">
        <form onSubmit={handleSubmit}>
          <FormControl id="title">
            <FormLabel>Title</FormLabel>
            <Input
              placeholder="Enter your proposal's title"
              focusBorderColor="#22e9a0"
              borderRadius="10px"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </FormControl>
          <FormControl id="description">
            <FormLabel mt="10px">Description</FormLabel>
            <Textarea
              placeholder="Enter your proposal's description"
              minH={{ base: "300px", md: "250px" }}
              focusBorderColor="#22e9a0"
              borderRadius="10px"
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </FormControl>
          <FormControl id="expiresAt">
            <FormLabel mt="10px">Expires At</FormLabel>
            <Input
              focusBorderColor="#22e9a0"
              borderRadius="10px"
              type="datetime-local"
              value={expiresAt}
              onChange={(e) => setExpiresAt(e.target.value)}
            />
          </FormControl>
          <Button
            isLoading={isSubmitting}
            borderRadius="10px"
            _hover={{ bg: "#22e9a0", color: "black" }}
            mt="10px"
            w="100%"
            type="submit"
          >
            Submit Proposal
          </Button>
        </form>
      </VStack>

      <Modal
        size={{ base: "full", md: "xs" }}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent
          bg="black"
          border="1.5px solid #181818"
          borderRadius="15px"
        >
          <ModalHeader>Not enough tokens</ModalHeader>
          <ModalCloseButton
            _hover={{
              color: "#22e9a0",
              borderRadius: "10px",
              border: "1px solid #22e9a0",
            }}
          />
          <ModalBody>
            To create and vote on proposals, you'll need at least 20k $DGNS. You
            currently have{" "}
            <Box color="#22e9a0" as="span" display="inline-flex">
              {balance}
            </Box>{" "}
            $DGNS.
            <Link
              isExternal
              href="https://app.uniswap.org/swap?outputCurrency=0x223028738503838E89Fc5fd5b1A42F1d024d9600&chain=mainnet"
              _hover={{ textDecoration: "none" }}
            >
              {" "}
              <Button
                borderRadius="10px"
                _hover={{ bg: "#22e9a0", color: "black" }}
                w="100%"
                mt="20px"
              >
                Purchase $DGNS
              </Button>
            </Link>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
      {/*  <Button onClick={onOpen}>hi</Button> */}
    </>
  );
}

export default CreateProposal;
