import React, { useState } from "react";
import {
  Box,
  Flex,
  HStack,
  Icon,
  Link,
  Image,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  IconButton,
  Button,
} from "@chakra-ui/react";
import { TbShieldBolt } from "react-icons/tb";
import { TbMoneybag } from "react-icons/tb";
import { IoWalletOutline } from "react-icons/io5";
import { FiMenu, FiChevronUp, FiChevronDown } from "react-icons/fi";
import { AiOutlineUser } from "react-icons/ai";
import logo from "../images/degeninsure.svg";
import { useDisconnect } from "wagmi";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { GoLaw } from "react-icons/go";

const Sidebar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { disconnect } = useDisconnect();
  const [isVoteDropdownOpen, setIsVoteDropdownOpen] = useState(false);

  const toggleVoteDropdown = () => {
    setIsVoteDropdownOpen(!isVoteDropdownOpen);
  };

  const sidebarData = [
    { name: "General Cover", link: "/app/getplan", icon: TbShieldBolt },
    { name: "dApp Cover", link: "/app/dapp-cover", icon: TbShieldBolt },
    { name: "Claim Payout", link: "/app/claim", icon: TbMoneybag },
    { name: "Your profile", link: "/app/profile", icon: AiOutlineUser },
    { name: "Your assets", link: "/app/assets", icon: IoWalletOutline },
    { name: "Vote", link: "/app/vote", icon: GoLaw },
  ];

  return (
    <div>
      <Box
        borderRight="1px solid #181818"
        p="30px"
        h="100vh"
        minW={{ md: "23%", lg: "15%" }}
        display={{ base: "none", md: "none", lg: "flex" }}
      >
        <Flex flexDir="column" alignItems="flex-start">
          <Link href="/" _hover={{ textDecoration: "none" }}>
            <HStack mb="40px">
              <Image src={logo} boxSize="30px" />
              <Box fontSize="20px" fontWeight="500">
                DegenInsure
              </Box>
            </HStack>
          </Link>

          {sidebarData?.map((e, i) => {
            return (
              <div key={i}>
                {e.name === "Vote" ? (
                  <>
                    <HStack
                      w="150px"
                      onClick={toggleVoteDropdown}
                      cursor="pointer"
                      color={isVoteDropdownOpen ? "#22e9a0" : "white"}
                      p="8px"
                      fontWeight="500"
                      mt="20px"
                    >
                      <Icon as={e.icon} />
                      <Box>{e.name}</Box>
                      <Icon
                        as={isVoteDropdownOpen ? FiChevronDown : FiChevronUp}
                        ml="auto"
                      />
                    </HStack>
                    {isVoteDropdownOpen && (
                      <div>
                        <Link
                          href="/app/vote"
                          _hover={{ textDecoration: "none" }}
                        >
                          <HStack
                            w="150px"
                            border={
                              window.location.pathname === "/app/vote"
                                ? "1px solid #22e9a0"
                                : "none"
                            }
                            color={
                              window.location.pathname === "/app/vote"
                                ? "#22e9a0"
                                : "white"
                            }
                            bg={
                              window.location.pathname === "/app/vote"
                                ? "#black"
                                : "none"
                            }
                            p={
                              window.location.pathname === "/app/vote"
                                ? "8px"
                                : "8px"
                            }
                            borderRadius={
                              window.location.pathname === "/app/vote"
                                ? "10px"
                                : "0px"
                            }
                            mt="20px"
                            fontWeight="500"
                            _hover={{
                              borderColor: "#22e9a0",
                              borderRadius: "10px",
                              p: "8px",
                              color: "#22e9a0",
                            }}
                          >
                            <Icon as={GoLaw} />
                            <Box>Proposals</Box>
                          </HStack>
                        </Link>
                      </div>
                    )}
                  </>
                ) : (
                  <Link
                    href={e.link}
                    key={i}
                    _hover={{ textDecoration: "none" }}
                  >
                    <HStack
                      w="150px"
                      border={
                        window.location.pathname === e.link
                          ? "1px solid #22e9a0"
                          : "none"
                      }
                      color={
                        window.location.pathname === e.link
                          ? "#22e9a0"
                          : "white"
                      }
                      bg={
                        window.location.pathname === e.link ? "#black" : "none"
                      }
                      p={window.location.pathname === e.link ? "8px" : "8px"}
                      borderRadius={
                        window.location.pathname === e.link ? "10px" : "0px"
                      }
                      mt="20px"
                      fontWeight="500"
                      _hover={{
                        borderColor: "#22e9a0",
                        borderRadius: "10px",
                        p: "8px",
                        color: "#22e9a0",
                      }}
                    >
                      <Icon as={e.icon} />
                      <Box>{e.name}</Box>
                    </HStack>
                  </Link>
                )}
              </div>
            );
          })}

          <Button
            mt="auto" // Push the button to the bottom of the sidebar
            ml="15px" // Add margin for spacing
            borderRadius="12px"
            onClick={() => disconnect()}
            _hover={{ color: "#EB453B" }}
            rightIcon={<HiOutlineArrowNarrowRight />}
          >
            Log Out
          </Button>
        </Flex>
      </Box>

      <Box m="15px" display={{ base: "flex", md: "flex", lg: "none" }}>
        <IconButton borderRadius="10px" onClick={onOpen} icon={<FiMenu />} />
      </Box>

      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent bg="black">
          <DrawerCloseButton />
          <DrawerHeader>
            {" "}
            <Link href="/" _hover={{ textDecoration: "none" }}>
              <HStack mb="20px">
                <Image src={logo} boxSize="30px" />
                <Box fontWeight="500">DegenInsure</Box>
              </HStack>
            </Link>
          </DrawerHeader>

          <DrawerBody borderRight="1px solid #181818">
            <Flex flexDir="column" alignItems="flex-start">
              {sidebarData?.map((e, i) => {
                return (
                  <div key={i}>
                    {e.name === "Vote" ? (
                      <>
                        <HStack
                          w="150px"
                          onClick={toggleVoteDropdown}
                          cursor="pointer"
                          color={isVoteDropdownOpen ? "#22e9a0" : "white"}
                          p="8px"
                          fontWeight="500"
                        >
                          <Icon as={e.icon} />
                          <Box>{e.name}</Box>
                          <Icon
                            as={
                              isVoteDropdownOpen ? FiChevronDown : FiChevronUp
                            }
                            ml="auto"
                          />
                        </HStack>
                        {isVoteDropdownOpen && (
                          <div>
                            <Link
                              href="/app/vote"
                              _hover={{ textDecoration: "none" }}
                            >
                              <HStack
                                w="150px"
                                border={
                                  window.location.pathname === "/app/vote"
                                    ? "1px solid #22e9a0"
                                    : "none"
                                }
                                color={
                                  window.location.pathname === "/app/vote"
                                    ? "#22e9a0"
                                    : "white"
                                }
                                bg={
                                  window.location.pathname === "/app/vote"
                                    ? "#black"
                                    : "none"
                                }
                                p={
                                  window.location.pathname === "/app/vote"
                                    ? "8px"
                                    : "8px"
                                }
                                borderRadius={
                                  window.location.pathname === "/app/vote"
                                    ? "10px"
                                    : "0px"
                                }
                                mt="20px"
                                fontWeight="500"
                                _hover={{
                                  borderColor: "#22e9a0",
                                  borderRadius: "10px",
                                  p: "8px",
                                  color: "#22e9a0",
                                }}
                              >
                                <Icon as={GoLaw} />
                                <Box>Proposals</Box>
                              </HStack>
                            </Link>
                          </div>
                        )}
                      </>
                    ) : (
                      <Link
                        href={e.link}
                        key={i}
                        _hover={{ textDecoration: "none" }}
                      >
                        <HStack
                          w="150px"
                          border={
                            window.location.pathname === e.link
                              ? "1px solid #22e9a0"
                              : "none"
                          }
                          color={
                            window.location.pathname === e.link
                              ? "#22e9a0"
                              : "white"
                          }
                          bg={
                            window.location.pathname === e.link
                              ? "#black"
                              : "none"
                          }
                          p={
                            window.location.pathname === e.link ? "8px" : "8px"
                          }
                          borderRadius={
                            window.location.pathname === e.link ? "10px" : "0px"
                          }
                          mt="20px"
                          fontWeight="500"
                          _hover={{
                            borderColor: "#22e9a0",
                            borderRadius: "10px",
                            p: "8px",
                            color: "#22e9a0",
                          }}
                        >
                          <Icon as={e.icon} />
                          <Box>{e.name}</Box>
                        </HStack>
                      </Link>
                    )}
                  </div>
                );
              })}
            </Flex>
          </DrawerBody>

          <DrawerFooter alignItems="flex-start" justifyContent="flex-start">
            {" "}
            <Button
              mt="auto" // Push the button to the bottom of the sidebar
              ml="15px" // Add margin for spacing
              borderRadius="12px"
              onClick={() => disconnect()}
              _hover={{ color: "#EB453B" }}
              rightIcon={<HiOutlineArrowNarrowRight />}
            >
              Log Out
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </div>
  );
};

export default Sidebar;
