import React, { useState, useEffect, useRef } from "react";
import {
  Flex,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Link,
} from "@chakra-ui/react";
import { useInView } from "framer-motion";
import { data } from "../resources/faqData";

const Section6 = () => {
  const ref = useRef(null);
  const isInView = useInView(ref);

  const [currentColor, setCurrentColor] = useState("white");
  const colors = ["#ff00ff", "#6b32e3", "#20cc55", "#22e9a0"]; // Colors to sample through
  const transitionDuration = 2000;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentColor((prevColor) => {
        const currentIndex = colors.indexOf(prevColor);
        const nextIndex = (currentIndex + 1) % colors.length;
        const nextColor = colors[nextIndex];

        if (nextColor === colors[colors.length - 1]) {
          clearInterval(interval); // Stop the interval once the final color is reached
        }

        return nextColor;
      });
    }, transitionDuration);

    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <>
      <Flex
        mt="100px"
        w="100%"
        alignItems="center"
        justifyContent="center"
        ref={ref}
      >
        <Flex
          w="100%"
          p={{ base: "30px", md: "100px" }}
          alignItems={{
            base: "space-between",
            md: "center",
            lg: "space-between",
          }}
          justifyContent={{
            base: "space-between",
            md: "center",
            lg: "space-between",
          }}
          flexDir={{ base: "column", md: "column", lg: "row" }}
        >
          <Flex
            w={{ base: "100%", md: "100%", lg: "30%" }}
            flexDir="column"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Box
              style={{
                transform: isInView ? "none" : "translateY(-200px)",
                opacity: isInView ? 1 : 0,
                transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                color: currentColor,
                transition: `color ${transitionDuration}ms ease-in-out`,
              }}
              fontSize="30px"
            >
              Frequently Asked Questions
            </Box>
          </Flex>

          <Box
            mt={{ base: "20px", md: "20px", lg: "0px" }}
            w={{ base: "100%", md: "100%", lg: "60%" }}
          >
            <Accordion allowToggle>
              {data?.map((e, i) => {
                return (
                  <>
                    <AccordionItem p="10px">
                      <h2>
                        <AccordionButton>
                          <Box as="span" flex="1" textAlign="left">
                            {e?.title}
                          </Box>
                          <AccordionIcon color="#22e9a0" />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>{e?.text}</AccordionPanel>
                    </AccordionItem>
                  </>
                );
              })}
            </Accordion>
          </Box>
        </Flex>
      </Flex>
    </>
  );
};

export default Section6;
