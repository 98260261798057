import {
  Box,
  Flex,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Tooltip,
  Heading,
  UnorderedList,
  ListItem,
  useToast,
  Link,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import { FaCalculator } from "react-icons/fa";
import { ethers } from "ethers";
import { degenInsureAbi } from "../resources/degenInsureAbi.js";
import AppNav from "../components/AppNav";
import { fetchUserByAddress } from "../helpers/getUserData";
import { useAccount } from "wagmi";
import axios from "axios";
import Confetti from "react-confetti";

const GetAPlan = ({ ethPrice }) => {
  const points = 10;
  const [userCovers, setUserCovers] = useState(0);
  const { address } = useAccount();
  const [weiValue, setWeiValue] = useState(0);
  const [userId, setUserId] = useState("");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isSuccessOpen,
    onOpen: onSuccessOpen,
    onClose: onSuccessClose,
  } = useDisclosure();
  const {
    isOpen: isWarningOpen,
    onOpen: onWarningOpen,
    onClose: onWarningClose,
  } = useDisclosure();
  const [usdValue, setUsdValue] = useState(0);
  const [ethValue, setEthValue] = useState("ETH Value");
  const [userInputETH, setUserInputETH] = useState(0);
  const [coverPrice, setCoverPrice] = useState(0);
  const [isPaying, setIsPaying] = useState(false);
  const toast = useToast();

  const provider =
    /*     window.ethereum &&  */ new ethers.providers.Web3Provider(
      window.ethereum
    ); //change to be RPC URL provider for separate states
  const contractAddress = "0xe3168795300220712f90A3E68ac111BB75215291";
  const contract = new ethers.Contract(
    contractAddress,
    degenInsureAbi,
    provider
  );

  const calculateWeiValue = () => {
    try {
      const ethValueInWei = ethers.utils.parseEther(userInputETH.toString());
      setWeiValue(ethValueInWei);
    } catch (e) {}
  };

  useEffect(() => {
    calculateWeiValue();
  }, [userInputETH]);

  async function calculatePayment(ethEquivalent) {
    try {
      // Convert ethEquivalent to Wei using ethers.utils.parseEther
      const ethValueInWei = ethers.utils.parseEther(ethEquivalent.toString());

      // Call the contract's calculatePayment function
      const paymentAmount = await contract.calculatePayment(ethValueInWei);

      // Convert the paymentAmount from BigNumber to a string
      const formattedPayment = ethers.utils.formatEther(paymentAmount);

      setCoverPrice(formattedPayment);
      return formattedPayment;
    } catch (error) {}
  }

  /*   useEffect(() => {
    try {
      setCoverPrice(ethers.utils.formatUnits(data, 18));
    } catch (e) {}
  }, [data]); */

  async function purchaseInsurance(ethEquivalent) {
    try {
      setIsPaying(true);
      // Ensure the user is connected to an Ethereum wallet
      if (!provider.getSigner()) {
        throw new Error("Please connect your Ethereum wallet.");
      }

      // Connect to the contract with a signer (user's wallet)
      const contractWithSigner = contract.connect(provider.getSigner());

      // Calculate the payment amount
      const paymentAmount = await calculatePayment(ethEquivalent);

      if (!paymentAmount) {
        throw new Error("Payment amount is invalid.");
      }

      // Send a transaction to the contract's purchaseInsurance function
      const tx = await contractWithSigner.purchaseInsurance(
        ethers.utils.parseEther(ethEquivalent.toString()),
        {
          value: ethers.utils.parseEther(paymentAmount.toString()), // Convert ETH to Wei
        }
      );

      await tx.wait();

      toast({
        description: "Plan successfully purchased",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      setIsPaying(false);
      handleUpdateDegenPoints();
      onSuccessOpen();
    } catch (error) {
      toast({
        description: "Error making purchase",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      setIsPaying(false);
    }
  }

  const handleBlur = (e) => {
    const newValue = e.target.value;
    setUsdValue(newValue);
  };

  const calculateETHValue = () => {
    const ETHValue = usdValue / ethPrice;
    setEthValue(ETHValue);
  };

  useEffect(() => {
    calculateETHValue();
  }, [usdValue, ethPrice]);

  const datas = [
    "Variable premiums depending on amount",
    "14 day max cover",
    "hard rug pull cover",
    "slow rugs cover",
    "abandoned projects cover",
  ];

  useEffect(() => {
    calculatePayment(userInputETH);
  }, [userInputETH]);

  useEffect(() => {
    try {
      if (!window.ethereum) {
        onWarningOpen(); // Automatically open the modal
      }
    } catch (e) {}
  }, [window.ethereum]);

  const fetchDatas = async () => {
    const datas = await fetchUserByAddress(address);
    setUserId(datas?._id);
  };

  useEffect(() => {
    fetchDatas();
  }, [address]);

  const handleUpdateDegenPoints = async () => {
    try {
      const response = await axios.put(
        `https://degeninsureserver.onrender.com/api/users/${userId}/updateDegenPoints`,
        { points },
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_API_KEY,
          },
        }
      );

      // You can update the UI to reflect the updated degenPoints if needed
    } catch (error) {}
  };

  const getNumberOfUserCovers = async (
    contractAddress,
    contractAbi,
    userAddress
  ) => {
    try {
      // Initialize a Web3 provider
      const provider = new ethers.providers.Web3Provider(window.ethereum);

      // Instantiate the contract using the provided contract address and ABI
      const contract = new ethers.Contract(
        contractAddress,
        contractAbi,
        provider
      );

      // Call the insurances function to retrieve insurance data
      const insurance = await contract.getNumberOfCoverPlans(userAddress);

      // Return the retrieved insurance data
      return insurance;
    } catch (error) {
      /*    throw error; */
    }
  };

  const fetchUserPlans = async () => {
    const plans = await getNumberOfUserCovers(
      "0xe3168795300220712f90A3E68ac111BB75215291",
      degenInsureAbi,
      address
    );
    setUserCovers(plans?.toString());
  };

  useEffect(() => {
    fetchUserPlans();
  }, [address]);
  return (
    <div>
      <AppNav />
      <Flex direction={{ base: "column", md: "row" }}>
        <Box>
          <Sidebar />
        </Box>

        <Flex
          justifyContent="center" // Center horizontally
          alignItems="center" // Center vertically
          flex="1" // Take up available space
          p={{ base: "20px", md: "30px" }}
          direction="column"
          mt={{ base: "0px", md: "200px", lg: "0px" }}
        >
          <Box
            w={{ base: "320px", md: "400px" }}
            h={{ base: "340px", md: "300px" }}
            borderRadius="15px"
            p="20px"
            border="1.5px solid #181818"
          >
            <Box
              w="100%"
              border="1.5px solid #181818"
              h={{ base: "100px", md: "80px" }}
              borderRadius="10px"
              p="15px"
            >
              <Flex w="100%" alignItems="center" justifyContent="space-between">
                <Box fontWeight="500">
                  Coverage amount (ETH){" "}
                  <Box
                    _hover={{ cursor: "pointer" }}
                    as="span"
                    display={{ base: "none", md: "inline-block" }}
                  >
                    <FaCalculator
                      onClick={onOpen}
                      color="#22e9a0"
                      size="10px"
                    />
                  </Box>
                  <Box
                    _hover={{ cursor: "pointer" }}
                    as="span"
                    display={{ base: "inline-block", md: "none" }}
                  >
                    <FaCalculator
                      onClick={onOpen}
                      color="#22e9a0"
                      size="15px"
                    />
                  </Box>
                </Box>
                <Box>
                  <Input
                    focusBorderColor="#22e9a0"
                    borderRadius="8px"
                    placeholder="40ETH"
                    onChange={(e) => setUserInputETH(e?.target?.value)}
                    value={userInputETH}
                  />
                </Box>
              </Flex>
            </Box>

            <Box
              mt="10px"
              w="100%"
              border="1.5px solid #181818"
              h={{ base: "100px", md: "80px" }}
              borderRadius="10px"
              p="15px"
            >
              <Flex w="100%" alignItems="center" justifyContent="space-between">
                <Box fontWeight="500"> Cover period</Box>
                <Box>
                  <Tooltip
                    bg="black"
                    border="1px solid #22e9a0"
                    borderRadius="7px"
                    label="More options coming soon"
                    color="#22e9a0"
                  >
                    <Button
                      isDisabled
                      _hover={{ bg: "#22e9a0", color: "black" }}
                      borderRadius="10px"
                    >
                      14 days
                    </Button>
                  </Tooltip>
                </Box>
              </Flex>
            </Box>

            <Flex
              mt="10px"
              fontWeight="500"
              w="100%"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>Cover price</Box>
              <Box color="#22e9a0">{coverPrice} ETH</Box>
            </Flex>

            <Button
              _hover={{ bg: "#22e9a0", color: "black" }}
              borderRadius="10px"
              mt="10px"
              w="100%"
              isLoading={isPaying}
              onClick={() => {
                purchaseInsurance(userInputETH);
              }}
              isDisabled={Number(userCovers) > 0 ? true : false}
              /*   onClick={() => {
                write({ args: [userInputETH] });
              }} */
            >
              Buy cover
            </Button>
          </Box>
          <Box
            w={{ base: "320px", md: "400px" }}
            h={{ base: "230px", md: "210px" }}
            borderRadius="15px"
            p="20px"
            border="1.5px solid #181818"
            mt="10px"
          >
            <Flex
              flexDir="column"
              w="100%"
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <Heading fontSize="22px">Cover details</Heading>
              <UnorderedList mt="10px">
                {datas?.map((e) => {
                  return (
                    <>
                      <ListItem>{e}</ListItem>
                    </>
                  );
                })}
              </UnorderedList>
            </Flex>
          </Box>
        </Flex>
      </Flex>

      <Modal
        size={{ base: "full", md: "md" }}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent
          bg="black"
          border="1.5px solid #181818"
          borderRadius="15px"
        >
          <ModalHeader>ETH converter</ModalHeader>
          <ModalCloseButton
            _hover={{
              color: "#22e9a0",
              borderRadius: "10px",
              border: "1px solid #22e9a0",
            }}
          />
          <ModalBody>
            <Box>
              <Input
                focusBorderColor="#22e9a0"
                borderRadius="8px"
                placeholder="Enter amount in USD"
                onBlur={handleBlur}
              />
            </Box>

            <Flex
              mt="10px"
              w="100%"
              alignItems="center"
              justifyContent="space-between"
            >
              <Input
                focusBorderColor="#22e9a0"
                borderRadius="8px"
                placeholder="ETH value"
                value={Number(ethValue)?.toFixed(3) + " ETH"}
              />
              <Box ml="5px">
                {" "}
                <Button
                  _hover={{ bg: "#22e9a0", color: "black" }}
                  borderRadius="10px"
                  onClick={() => {
                    setUserInputETH(ethValue);
                    onClose();
                  }}
                >
                  Click to set
                </Button>
              </Box>
            </Flex>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        size={{ base: "full", md: "xs" }}
        isOpen={isWarningOpen}
        onClose={onWarningClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          bg="black"
          border="1.5px solid #181818"
          borderRadius="20px"
        >
          <ModalHeader color="#22e9a0">
            Your experience may be limited
          </ModalHeader>
          <ModalCloseButton
            _hover={{
              color: "#22e9a0",
              borderRadius: "10px",
              border: "1px solid #22e9a0",
            }}
          />
          <ModalBody>
            {" "}
            {/*   <Lottie loop="false" animationData={completed} /> */}
            <Box>
              It seems you either haven't installed Metamask or you're not using
              DegenInsure in a wallet's dApp browser. For the best experience,
              please use DegenInsure in a wallet browser or install Metamask or
              any other wallet.
            </Box>
            <Link
              href="https://ethereum.org/en/wallets/find-wallet/"
              isExternal
            >
              <Button
                _hover={{ bg: "#22e9a0", color: "black" }}
                w="100%"
                mt="10px"
                borderRadius="10px"
              >
                Get a wallet
              </Button>
            </Link>
            <Link isExternal href="https://ethereum.org/en/wallets/">
              {" "}
              <Button
                _hover={{ bg: "#22e9a0", color: "black" }}
                w="100%"
                mt="10px"
                borderRadius="10px"
              >
                What's a wallet?
              </Button>
            </Link>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>

      <Box>
        {isSuccessOpen && <Confetti />}
        <Modal
          size={{ base: "full", md: "xs" }}
          isOpen={isSuccessOpen}
          onClose={onSuccessClose}
          isCentered
        >
          <ModalOverlay />
          <ModalContent
            bg="black"
            border="1.5px solid #181818"
            borderRadius="20px"
          >
            <ModalHeader color="#22e9a0">Plan purchase successful!</ModalHeader>
            <ModalCloseButton
              _hover={{
                color: "#22e9a0",
                borderRadius: "10px",
                border: "1px solid #22e9a0",
              }}
            />
            <ModalBody>
              You've just bought a DegenInsure plan and earned{" "}
              <Box color="#22e9a0" as="span" display="inline-block">
                10
              </Box>{" "}
              DegenPoints! Congratulations! See what you can do with your
              DegenPoints in our{" "}
              <Link href="https://degeninsure.gitbook.io/welcome/">
                {" "}
                <Box color="#22e9a0" as="span" display="inline-block">
                  docs
                </Box>
              </Link>{" "}
              or view how many DegenPoints you have in your{" "}
              <Link href="/app/profile">
                <Box color="#22e9a0" as="span" display="inline-block">
                  profile
                </Box>
              </Link>
              .
            </ModalBody>

            <ModalFooter></ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </div>
  );
};

export default GetAPlan;
