import React, { lazy, Suspense, useState, useEffect, useRef } from "react";
import gif1 from "../images/section4gif.gif";
import { Flex, Image, Box, Grid, GridItem, Icon, Text } from "@chakra-ui/react";
import { FaCheckCircle } from "react-icons/fa";
import { useInView } from "framer-motion";

const Section4 = () => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const gridData = [
    { text: "Real time balances", isChecked: true },
    { text: "Real time values", isChecked: false },
    { text: "Last transferred date", isChecked: true },
    { text: "Spam tokens filtered", isChecked: false },
    { text: "All assets included", isChecked: true },
    { text: "Asset value in any currency", isChecked: false },
  ];
  const [currentColor, setCurrentColor] = useState("white");
  const colors = ["#ff00ff", "#6b32e3", "#20cc55", "#22e9a0"]; // Colors to sample through
  const transitionDuration = 2000;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentColor((prevColor) => {
        const currentIndex = colors.indexOf(prevColor);
        const nextIndex = (currentIndex + 1) % colors.length;
        const nextColor = colors[nextIndex];

        if (nextColor === colors[colors.length - 1]) {
          clearInterval(interval); // Stop the interval once the final color is reached
        }

        return nextColor;
      });
    }, transitionDuration);

    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <>
      <Flex
        mt="100px"
        w="100%"
        alignItems="center"
        justifyContent="center"
        ref={ref}
      >
        <Flex
          p={{ base: "30px", md: "100px" }}
          alignItems={{
            base: "space-between",
            md: "center",
            lg: "space-between",
          }}
          justifyContent={{
            base: "space-between",
            md: "center",
            lg: "space-between",
          }}
          flexDir={{ base: "column", md: "column", lg: "row" }}
        >
          <Flex
            w="100%"
            flexDir="column"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Box
              style={{
                transform: isInView ? "none" : "translateY(-200px)",
                opacity: isInView ? 1 : 0,
                transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                color: currentColor,
                transition: `color ${transitionDuration}ms ease-in-out`,
              }}
              w={{ base: "100%", md: "100%", lg: "50%" }}
              fontSize="30px"
            >
              View ALL your assets.
            </Box>

            <Box w={{ base: "100%", md: "90%" }} mt="20px">
              See your assets in real time to know what you want to cover. All
              assets are included along with their prices so you can know your
              total risked amount at a glance.
            </Box>

            <Grid
              mt="20px"
              templateRows="repeat(3, 1fr)"
              templateColumns="repeat(2, 1fr)"
              gap={3}
            >
              {gridData.map((item, index) => (
                <GridItem
                  key={index}
                  p={3}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Flex alignItems="center">
                    <Icon
                      as={FaCheckCircle}
                      style={{
                        color: currentColor,
                        transition: `color ${transitionDuration}ms ease-in-out`,
                      }}
                      mr={2}
                    />
                    <Text>{item.text}</Text>
                  </Flex>
                </GridItem>
              ))}
            </Grid>
          </Flex>

          <Suspense fallback={<div>Loading...</div>}>
            <Image
              style={{
                transform: isInView ? "none" : "translateY(200px)",
                opacity: isInView ? 1 : 0,
                transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
              }}
              mt={{ base: "20px", md: "40px", lg: "0px" }}
              w={{ base: "100%", md: "50%" }}
              border="6px solid #131014"
              boxSize={{ base: "100%", md: "100%", lg: "50%" }}
              src={"https://i.imgur.com/zVFM4F1.png"}
              borderRadius="20px"
            />
          </Suspense>
        </Flex>
      </Flex>
    </>
  );
};

export default Section4;
