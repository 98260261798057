import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Flex,
  Button,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Image,
  HStack,
  Avatar,
  IconButton,
  Divider,
  useToast,
  Link,
  VStack,
  Switch,
} from "@chakra-ui/react";
import { IoSettingsOutline } from "react-icons/io5";
import axios from "axios"; // Import Axios
import { getQuote } from "./getQuote";
import Sidebar from "../../components/Sidebar";
import AppNav from "../../components/AppNav";
import { useAccount } from "wagmi";
import { getFromTokenBalance } from "./getFromTokenBalance";
import { fetchTokens } from "./fetchTokens";
import { ArrowDownIcon } from "@chakra-ui/icons";
import { ethers } from "ethers";
import Countdown from "./CountDown";
import Confetti from "react-confetti";
import { fetchUserByAddress } from "../../helpers/getUserData";

const DEX = () => {
  const [points, setPoints] = useState(150);
  const [userId, setUserId] = useState("");
  const [approvalTx, setApprovalTx] = useState({});
  const [isApproving, setIsApproving] = useState(false);
  const [showApprove, setShowApprove] = useState(false);
  const [allowanceData, setAllowanceData] = useState(null);
  const [isSwapping, setIsSwapping] = useState(false);
  const [tokens, setTokens] = useState([]);
  const toast = useToast();
  const { address } = useAccount();
  const [quote, setQuote] = useState("");
  const {
    incurrency: routeInCurrency,
    inamount: routeInAmount,
    outcurrency: routeOutCurrency,
  } = useParams();
  const [inCurrency, setInCurrency] = useState(
    routeInCurrency || "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE"
  );
  const [inAmount, setInAmount] = useState(routeInAmount || "1");
  const [outCurrency, setOutCurrency] = useState(
    routeOutCurrency || "0x223028738503838E89Fc5fd5b1A42F1d024d9600"
  );
  const [quoteData, setQuoteData] = useState(null);
  const [slippage, setSlippage] = useState(12);
  const [decimals, setDecimals] = useState("18");
  const [fromTokenSymbol, setFromTokenSymbol] = useState("ETH");
  const [fromTokenLogo, setFromTokenLogo] = useState(
    "https://tokens.1inch.io/0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee.png"
  );
  const [fromTokenBalance, setFromTokenBalance] = useState("0");
  const [fromTokenSearch, setFromTokenSearch] = useState("");
  const [fromTokenPrice, setFromTokenPrice] = useState("");
  const [toTokenSymbol, setToTokenSymbol] = useState("DGNS");
  const [txInfo, setTxInfo] = useState("");
  const [swappingData, setSwappingData] = useState("");
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [toTokenLogo, setToTokenLogo] = useState(
    "https://assets.coingecko.com/coins/images/32185/small/Frame_12_%282%29.png?1696756306"
  );
  const [toTokenDecimals, setToTokenDecimals] = useState("18");
  const [toTokenSearch, setToTokenSearch] = useState("");
  const [toTokenPrice, setToTokenPrice] = useState("");
  const [swapData, setSwapData] = useState("");
  const [showApprovalToast, setShowApprovalToast] = useState(false);
  const {
    isOpen: isFromTokenOpen,
    onOpen: onFromTokenOpen,
    onClose: onFromTokenClose,
  } = useDisclosure();

  const {
    isOpen: isToTokenOpen,
    onOpen: onToTokenOpen,
    onClose: onToTokenClose,
  } = useDisclosure();

  const {
    isOpen: isSwapStartedOpen,
    onOpen: onSwapStartedOpen,
    onClose: onSwapStartedClose,
  } = useDisclosure();
  const {
    isOpen: isSwapCompletedOpen,
    onOpen: onSwapCompletedOpen,
    onClose: onSwapCompletedClose,
  } = useDisclosure();

  const {
    isOpen: isSwapSettingsOpen,
    onOpen: onSwapSettingsOpen,
    onClose: onSwapSettingsClose,
  } = useDisclosure();

  useEffect(() => {
    const getQuotes = async () => {
      const quotee = await getQuote(
        inCurrency,
        outCurrency,
        inAmount,
        slippage
      );
      setQuote(quotee);
    };
    getQuotes();
  }, [inCurrency, inAmount, outCurrency]);

  useEffect(() => {
    const getTokens = async () => {
      const tokenss = await fetchTokens();
      setTokens(tokenss);
    };
    getTokens();
  }, []);

  const switchTokens = () => {
    const token1 = inCurrency;
    const token2 = outCurrency;
    const token1symbol = fromTokenSymbol;
    const token2symbol = toTokenSymbol;
    const token1logo = fromTokenLogo;
    const token2logo = toTokenLogo;
    const token1decimals = decimals;
    const token2decimals = toTokenDecimals;
    setInCurrency(token2);
    setOutCurrency(token1);
    setDecimals(token2decimals);
    setToTokenDecimals(token1decimals);
    setFromTokenLogo(token2logo);
    setToTokenLogo(token1logo);
    setFromTokenSymbol(token2symbol);
    setToTokenSymbol(token1symbol);
  };

  const getSwapData = async () => {
    try {
      const data = await axios.get(
        "https://degeninsureserver.onrender.com/getSwapQuote",
        {
          params: {
            inTokenAddress: inCurrency,
            outTokenAddress: outCurrency,
            account: address,
            amount: inAmount,
            gasPrice: quote?.estimatedGas,
            slippage: slippage,
          },
        }
      );

      setSwapData(data);
    } catch (e) {}
  };
  useEffect(() => {
    getSwapData();
  }, [inCurrency, outCurrency, inAmount]);

  const handleGetAllowance = async () => {
    try {
      const chainId = "1"; // Replace with the actual chain ID
      const fromTokenAddress = inCurrency; // Replace with the fromTokenAddress
      const allowanceTarget = swapData?.data?.to; // Replace with the allowance target

      const response = await axios.get(
        `https://degeninsureserver.onrender.com/getAllowance`, // Change to the actual backend URL
        {
          params: {
            chainId,
            address,
            fromTokenAddress,
            allowanceTarget,
          },
        }
      );

      setAllowanceData(response.data.data[0]);
    } catch (error) {
      setAllowanceData(null);
    }
  };

  useEffect(() => {
    handleGetAllowance();
  }, [swapData]);

  const approve = async () => {
    try {
      setIsApproving(true);
      const provider1 = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider1.getSigner();
      const tokenContract = new ethers.Contract(
        inCurrency,
        ["function approve(address spender, uint256 amount)"],
        signer
      );
      const tx = await tokenContract.approve(
        swapData?.data?.to,
        ethers.utils.parseUnits(String(inAmount), decimals)
      );
      await tx.wait();
      setIsApproving(false);
      setApprovalTx(tx);
    } catch (e) {
      setIsApproving(false);
    }
  };

  const txnCheck = async () => {
    const provider1 = new ethers.providers.Web3Provider(window.ethereum);
    try {
      let txn_test = await provider1?.getTransaction(approvalTx?.hash);

      if (txn_test) {
        if (txn_test.blockNumber) {
          setIsApproving(false);
          setShowApprove(false);
          setShowApprovalToast(true);
        }
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (showApprovalToast) {
      toast({
        title: "Approved!",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [showApprovalToast]);

  useEffect(() => {
    if (approvalTx !== null) {
      setInterval(() => {
        txnCheck();
      }, 1000);
    }
  }, [approvalTx]);

  const swap = async () => {
    try {
      const params = {
        from: address,
        to: swapData?.data?.to,
        data: swapData?.data?.data,
        value: swapData?.data?.value,
        gasLimit: swapData?.data?.estimatedGas,
      };

      setIsSwapping(true);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      const tx = await signer.sendTransaction(params);
      setTxInfo(tx);
      setSwappingData(tx);
      const receipt = await tx.wait();
      if (receipt?.transactionHash) {
        setIsSwapping(false);
        setTimeRemaining(0);
        onSwapStartedOpen();
      }
    } catch (e) {
    } finally {
      setIsSwapping(false);
    }
  };

  const [swapCompleted, setSwapCompleted] = useState(false);

  const swapTxnCheck = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    try {
      let txn_test = await provider?.getTransaction(swappingData?.hash);
      if (txn_test) {
        if (txn_test.blockNumber) {
          setSwapCompleted(true);
        }
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (txInfo !== null) {
      setInterval(() => {
        swapTxnCheck();
      }, 1000);
    }
  }, [txInfo]);

  useEffect(() => {
    if (swapData?.data?.to == undefined) {
      try {
        getSwapData();
      } catch (e) {}
    }
  }, [swapData]);

  useEffect(() => {
    if (swapCompleted === true) {
      addTrade();
      handleUpdateDegenPoints();
      onSwapStartedClose();
    }
  }, [swapCompleted]);

  const getFrmTknBal = async () => {
    const balan = await getFromTokenBalance(address, inCurrency);
    setFromTokenBalance(balan / 10 ** decimals);
  };

  useEffect(() => {
    getFrmTknBal();
  }, [inCurrency]);

  const addTrade = async () => {
    try {
      const response = await axios.post(
        "https://degeninsureserver.onrender.com/api/addTrade",
        {
          userAddress: address,
          fromTokenAddress: inCurrency,
          toTokenAddress: outCurrency,
          fromTokenAmount: inAmount,
          toTokenAmount: quote?.outAmount / 10 ** quote?.outToken?.decimals,
          fromTokenSymbol: fromTokenSymbol,
          toTokenSymbol: toTokenSymbol,
          fromTokenLogo: fromTokenLogo,
          toTokenLogo: toTokenLogo,
          fromTokenDecimals: decimals,
          toTokenDecimals: toTokenDecimals,
          fromTokenPrice: fromTokenPrice,
          toTokenPrice: toTokenPrice,
          degenPointsAdded: points,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_API_KEY,
          },
        }
      );

      // Check if the trade was added successfully
    } catch (error) {}
  };

  const fetchDatas = async () => {
    const datas = await fetchUserByAddress(address);
    setUserId(datas?._id);
  };

  useEffect(() => {
    fetchDatas();
  }, [address]);

  const handleUpdateDegenPoints = async () => {
    try {
      const response = await axios.put(
        `https://degeninsureserver.onrender.com/api/users/${userId}/updateDegenPoints`,
        { points },
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_API_KEY,
          },
        }
      );

      // You can update the UI to reflect the updated degenPoints if needed
    } catch (error) {}
  };

  useEffect(() => {
    if (String(inCurrency) === "0x223028738503838E89Fc5fd5b1A42F1d024d9600") {
      setSlippage(12);
    } else {
      setSlippage(1);
    }
  }, [inCurrency]);

  useEffect(() => {
    axios
      .get(
        `https://apiv5.paraswap.io/prices?srcToken=${inCurrency}&srcDecimals=18&destToken=0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48&destDecimals=6&amount=1000000000000000000&side=SELL&network=1`
      )
      .then((data) => {
        setFromTokenPrice(data?.data?.priceRoute?.destUSD);
      });

    axios
      .get(
        `https://apiv5.paraswap.io/prices?srcToken=${outCurrency}&srcDecimals=18&destToken=0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48&destDecimals=6&amount=1000000000000000000&side=SELL&network=1`
      )
      .then((data) => {
        setToTokenPrice(data?.data?.priceRoute?.destUSD / 10 ** 12);
      });
  }, []);

  useEffect(() => {
    if (inCurrency == "0x223028738503838E89Fc5fd5b1A42F1d024d9600") {
      setPoints(1000);
    }
  }, [inCurrency]);

  return (
    <div>
      <AppNav />
      <Flex direction={{ base: "column", md: "row" }}>
        <Box>
          <Sidebar />
        </Box>
        {isSwapCompletedOpen && <Confetti />}
        <Flex
          justifyContent="center" // Center horizontally
          alignItems="center" // Center vertically
          flex="1" // Take up available space
          p={{ base: "20px", md: "30px" }}
          direction="column"
          mt={{ base: "0px", md: "200px", lg: "0px" }}
        >
          <Box
            w={{ base: "320px", md: "400px" }}
            h={{ base: "200px", md: "200px" }}
            borderRadius="15px"
            p="20px"
            border="1.5px solid #181818"
          >
            <Flex
              flexDir="column"
              w="100%"
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <Flex w="100%" alignItems="flex-end" justifyContent="flex-end">
                <Box>
                  <IconButton
                    onClick={() => onSwapSettingsOpen()}
                    size="sm"
                    borderRadius="10px"
                    icon={<IoSettingsOutline />}
                  />
                </Box>
              </Flex>
              <Box>You're selling</Box>
              <Flex
                mt="10px"
                w="100%"
                alignItems="center"
                justifyContent="space-between"
              >
                <Button onClick={onFromTokenOpen} borderRadius="10px">
                  <HStack>
                    <Avatar
                      src={fromTokenLogo}
                      boxSize="20px"
                      borderRadius="300px"
                      name={fromTokenSymbol}
                    />

                    <Box>{fromTokenSymbol}</Box>
                  </HStack>
                </Button>

                <Input
                  borderRadius="10px"
                  value={inAmount}
                  focusBorderColor="#22e9a0"
                  fontSize="25px"
                  type="number"
                  placeholder="Enter amount"
                  border="1px solid #181818"
                  fontWeight="500"
                  _placeholder={{
                    fontSize: "18px",
                    fontWeight: "500",
                  }}
                  w="50%"
                  onChange={(e) => setInAmount(e.target.value)}
                />
              </Flex>

              <Flex
                mt="20px"
                w="100%"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box>
                  You have{" "}
                  <Box color="#22e9a0" as="span" display="inline-flex">
                    {Number(fromTokenBalance)?.toFixed(3)}
                  </Box>{" "}
                  {fromTokenSymbol}
                </Box>

                <Button
                  _hover={{ bg: "#22e9a0", color: "black" }}
                  onClick={() =>
                    String(inCurrency) ==
                    "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE"
                      ? setInAmount(Number(fromTokenBalance) * 0.9)
                      : setInAmount(Number(fromTokenBalance))
                  }
                  borderRadius="10px"
                  size="sm"
                >
                  Use MAX
                </Button>
              </Flex>
            </Flex>
          </Box>

          <Box mt="30px">
            <IconButton
              onClick={() => switchTokens()}
              size="sm"
              borderRadius="10px"
              icon={<ArrowDownIcon />}
            />
          </Box>

          <Box
            w={{ base: "320px", md: "400px" }}
            h={{ base: "360px", md: "360px" }}
            borderRadius="15px"
            p="20px"
            border="1.5px solid #181818"
            mt="30px"
          >
            <Flex
              flexDir="column"
              w="100%"
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <Box>You'll get</Box>
              <Flex
                mt="10px"
                w="100%"
                alignItems="center"
                justifyContent="space-between"
              >
                <Button onClick={onToTokenOpen} borderRadius="10px">
                  <HStack>
                    <Avatar
                      src={toTokenLogo}
                      boxSize="20px"
                      borderRadius="300px"
                      name={toTokenSymbol}
                    />

                    <Box>{toTokenSymbol}</Box>
                  </HStack>
                </Button>

                <Input
                  borderRadius="10px"
                  value={quote?.outAmount / 10 ** quote?.outToken?.decimals}
                  focusBorderColor="#22e9a0"
                  fontSize="25px"
                  type="number"
                  placeholder="Enter amount"
                  border="1px solid #181818"
                  fontWeight="500"
                  _placeholder={{
                    fontSize: "18px",
                    fontWeight: "500",
                  }}
                  w="50%"
                />
              </Flex>

              {/* <Flex
                mt="20px"
                w="100%"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box>Gas cost</Box>
                <Box>{}</Box>
              </Flex> */}

              {/*    <Divider mt="10px" mb="10px" /> */}

              <Flex
                mt="20px"
                w="100%"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box>It'll take</Box>
                <Box color="#22e9a0">{quote?.useTime / 100 || 0}s</Box>
              </Flex>

              <Divider mt="10px" mb="10px" />

              <Flex w="100%" alignItems="center" justifyContent="space-between">
                <Box>Improved Route Gains</Box>
                <Box color="#22e9a0">
                  ${quote?.save < 0 ? quote?.save * -1 : quote?.save}
                </Box>
              </Flex>

              <Divider mt="10px" mb="10px" />

              <Flex w="100%" alignItems="center" justifyContent="space-between">
                <Box>Sending value</Box>
                <Box color="#22e9a0">
                  ${Number(inAmount * fromTokenPrice)?.toFixed(3)}
                </Box>
              </Flex>

              <Divider mt="10px" mb="10px" />

              <Flex w="100%" alignItems="center" justifyContent="space-between">
                <Box>Receiving value</Box>
                <Box color="#22e9a0">
                  $
                  {Number(
                    (quote?.outAmount / 10 ** quote?.outToken?.decimals) *
                      toTokenPrice || toTokenPrice
                  )?.toFixed(3)}
                </Box>
              </Flex>

              <Divider mt="10px" mb="10px" />

              <Flex w="100%" alignItems="center" justifyContent="space-between">
                <Box>Routing through</Box>
                <Box color="#22e9a0">
                  {quote?.path?.routes[0]?.subRoutes[0]?.dexes[0]?.dex}
                </Box>
              </Flex>
            </Flex>
          </Box>

          

          <Button
            _hover={{ bg: "#22e9a0", color: "black" }}
            mt="10px"
            isLoading={isSwapping}
            onClick={() => swap()}
            w={{ base: "320px", md: "400px" }}
            borderRadius="10px"
          >
            Make Trade
          </Button>
        </Flex>
      </Flex>

      <Modal
        size={{ base: "full", md: "sm" }}
        isOpen={isFromTokenOpen}
        onClose={onFromTokenClose}
      >
        <ModalOverlay />
        <ModalContent
          border="1.5px solid #181818"
          bg="black"
          borderRadius="20px"
        >
          <ModalHeader></ModalHeader>
          <ModalCloseButton
            _hover={{
              color: "#22e9a0",
              borderRadius: "10px",
              border: "1px solid #22e9a0",
            }}
            borderRadius="10px"
          />
          <ModalBody>
            <Input
              focusBorderColor="#22e9a0"
              onChange={(e) => setFromTokenSearch(e?.target?.value)}
              mt="20px"
              borderRadius="10px"
              placeholder="Search for a token"
            />

            <Box
              mt="10px"
              w="100%"
              maxH={{ base: "80vh", md: "50vh" }}
              overflowY="scroll"
              borderRadius="20px"
              border="1px solid #242527 "
              p="15px"
            >
              <Box
                onClick={() => {
                  setDecimals(18);
                  setInCurrency("0x223028738503838E89Fc5fd5b1A42F1d024d9600");
                  setFromTokenLogo(
                    "https://www.degeninsure.com/static/media/degeninsure.a903fa0b45d70f87d42773141f8827a0.svg"
                  );
                  setFromTokenSymbol("DGNS");
                  onFromTokenClose();
                }}
                mt="10px"
                _hover={{ bg: "black", cursor: "pointer" }}
                w="100%"
                h="70px"
                borderRadius="20px"
                p="10px"
              >
                <Flex
                  w="100%"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <HStack>
                    <Image
                      src="https://www.degeninsure.com/static/media/degeninsure.a903fa0b45d70f87d42773141f8827a0.svg"
                      boxSize="40px"
                      borderRadius="300px"
                    />
                    <Flex
                      fontWeight="600"
                      flexDir="column"
                      alignItems="flex-start"
                    >
                      <Box>DegenInsure</Box>
                      <Box color="gray.500">DGNS</Box>
                    </Flex>
                  </HStack>
                </Flex>
              </Box>
              {tokens
                ?.filter((val) => {
                  if (fromTokenSearch == "") {
                    return val;
                  } else if (
                    val.address
                      .toLowerCase()
                      .includes(fromTokenSearch.toLowerCase()) ||
                    val.name
                      .toLowerCase()
                      .includes(fromTokenSearch.toLowerCase()) ||
                    val.symbol
                      .toLowerCase()
                      .includes(fromTokenSearch.toLowerCase())
                  ) {
                    return val;
                  }
                })
                ?.map((e, i) => {
                  return (
                    <>
                      <Box
                        key={e?.address}
                        onClick={() => {
                          setDecimals(e?.decimals);
                          setInCurrency(e?.address);
                          setFromTokenLogo(e?.icon);
                          setFromTokenPrice(e?.usd);
                          setFromTokenSymbol(e?.symbol);
                          onFromTokenClose();
                        }}
                        mt="10px"
                        _hover={{ bg: "black", cursor: "pointer" }}
                        w="100%"
                        h="70px"
                        borderRadius="20px"
                        p="10px"
                      >
                        <Flex
                          w="100%"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <HStack>
                            <Image
                              src={e?.icon}
                              boxSize="40px"
                              borderRadius="300px"
                            />
                            <Flex
                              fontWeight="600"
                              flexDir="column"
                              alignItems="flex-start"
                            >
                              <Box>{e?.name}</Box>
                              <Box color="gray.500">{e?.symbol}</Box>
                            </Flex>
                          </HStack>
                        </Flex>
                      </Box>
                    </>
                  );
                })}
            </Box>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        size={{ base: "full", md: "sm" }}
        isOpen={isToTokenOpen}
        onClose={onToTokenClose}
      >
        <ModalOverlay />
        <ModalContent
          border="1.5px solid #181818"
          bg="black"
          borderRadius="20px"
        >
          <ModalHeader></ModalHeader>
          <ModalCloseButton
            _hover={{
              color: "#22e9a0",
              borderRadius: "10px",
              border: "1px solid #22e9a0",
            }}
            borderRadius="10px"
          />
          <ModalBody>
            <Input
              focusBorderColor="#22e9a0"
              onChange={(e) => setToTokenSearch(e?.target?.value)}
              mt="20px"
              borderRadius="10px"
              placeholder="Search for a token"
            />

            <Box
              mt="10px"
              w="100%"
              maxH={{ base: "80vh", md: "50vh" }}
              overflowY="scroll"
              borderRadius="20px"
              border="1px solid #242527 "
              p="15px"
            >
              <Box
                onClick={() => {
                  setToTokenDecimals(18);
                  setOutCurrency("0x223028738503838E89Fc5fd5b1A42F1d024d9600");
                  setToTokenLogo(
                    "https://www.degeninsure.com/static/media/degeninsure.a903fa0b45d70f87d42773141f8827a0.svg"
                  );
                  setToTokenSymbol("DGNS");
                  onToTokenClose();
                }}
                mt="10px"
                _hover={{ bg: "black", cursor: "pointer" }}
                w="100%"
                h="70px"
                borderRadius="20px"
                p="10px"
              >
                <Flex
                  w="100%"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <HStack>
                    <Image
                      src="https://www.degeninsure.com/static/media/degeninsure.a903fa0b45d70f87d42773141f8827a0.svg"
                      boxSize="40px"
                      borderRadius="300px"
                    />
                    <Flex
                      fontWeight="600"
                      flexDir="column"
                      alignItems="flex-start"
                    >
                      <Box>DegenInsure</Box>
                      <Box color="gray.500">DGNS</Box>
                    </Flex>
                  </HStack>
                </Flex>
              </Box>
              {tokens
                ?.filter((val) => {
                  if (toTokenSearch == "") {
                    return val;
                  } else if (
                    val.address
                      .toLowerCase()
                      .includes(toTokenSearch.toLowerCase()) ||
                    val.name
                      .toLowerCase()
                      .includes(toTokenSearch.toLowerCase()) ||
                    val.symbol
                      .toLowerCase()
                      .includes(toTokenSearch.toLowerCase())
                  ) {
                    return val;
                  }
                })
                ?.map((e, i) => {
                  return (
                    <>
                      <Box
                        key={e?.address}
                        onClick={() => {
                          setToTokenDecimals(e?.decimals);
                          setOutCurrency(e?.address);
                          setToTokenLogo(e?.icon);
                          setToTokenPrice(e?.usd);
                          setToTokenSymbol(e?.symbol);
                          onToTokenClose();
                        }}
                        mt="10px"
                        _hover={{ bg: "black", cursor: "pointer" }}
                        w="100%"
                        h="70px"
                        borderRadius="20px"
                        p="10px"
                      >
                        <Flex
                          w="100%"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <HStack>
                            <Image
                              src={e?.icon}
                              boxSize="40px"
                              borderRadius="300px"
                            />
                            <Flex
                              fontWeight="600"
                              flexDir="column"
                              alignItems="flex-start"
                            >
                              <Box>{e?.name}</Box>
                              <Box color="gray.500">{e?.symbol}</Box>
                            </Flex>
                          </HStack>
                        </Flex>
                      </Box>
                    </>
                  );
                })}
            </Box>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        size="full"
        isOpen={isSwapStartedOpen}
        onClose={onSwapStartedClose}
      >
        <ModalOverlay />
        <ModalContent p="40px" borderRadius="10px" bg="black">
          <ModalHeader>
            <Flex
              flexDir="column"
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <Box>Total remaining time</Box>
              {/*  <Box fontSize={{ base: "40px", md: "60px" }}>Time</Box> */}
            </Flex>
          </ModalHeader>
          <ModalCloseButton
            _hover={{
              color: "#22e9a0",
              borderRadius: "10px",
              border: "1px solid #22e9a0",
            }}
            borderRadius="10px"
          />
          <ModalBody>
            <Countdown
              durationInSeconds={Math.floor(Number(quote?.useTime) / 10)}
              fromTokenImage={fromTokenLogo}
              toTokenImage={toTokenLogo}
            />
            <Flex
              w="100%"
              alignItems="center"
              justifyContent="flex-start"
            ></Flex>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        size={{ base: "full", md: "sm" }}
        isOpen={isSwapCompletedOpen}
        onClose={onSwapCompletedClose}
      >
        <ModalOverlay />
        <ModalContent
          border="1.5px solid #181818"
          bg="black"
          borderRadius="20px"
        >
          <ModalHeader>Your swap is done!</ModalHeader>
          <ModalCloseButton
            _hover={{
              color: "#22e9a0",
              borderRadius: "10px",
              border: "1px solid #22e9a0",
            }}
            borderRadius="10px"
          />
          <ModalBody>
            <Flex
              mt="20px"
              w="100%"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>It took</Box>
              <Box color="#22e9a0">{quote?.useTime / 100}s</Box>
            </Flex>

            <Divider mt="10px" mb="10px" />

            <Flex w="100%" alignItems="center" justifyContent="space-between">
              <Box>You gained an extra</Box>
              <Box color="#22e9a0">
                ${quote?.save < 0 ? quote?.save * -1 : quote?.save}
              </Box>
            </Flex>

            <Divider mt="10px" mb="10px" />

            <Flex w="100%" alignItems="center" justifyContent="space-between">
              <Box>We routed through</Box>
              <Box color="#22e9a0">
                {quote?.path?.routes[0]?.subRoutes[0]?.dexes[0]?.dex}
              </Box>
            </Flex>
            <Divider mt="10px" mb="10px" />
            <VStack w="100%">
              <Link
                w="100%"
                href={`https://etherscan.io/tx/${swappingData?.hash}`}
                _hover={{ textDecoration: "none" }}
                isExternal
              >
                {" "}
                <Button
                  borderRadius="10px"
                  _hover={{ bg: "#22e9a0", color: "black" }}
                  w="100%"
                  mt="30px"
                >
                  View on explorer
                </Button>
              </Link>
            </VStack>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        size={{ base: "full", md: "sm" }}
        isOpen={isSwapSettingsOpen}
        onClose={onSwapSettingsClose}
      >
        <ModalOverlay />
        <ModalContent
          border="1.5px solid #181818"
          bg="black"
          borderRadius="20px"
        >
          <ModalHeader>Swap Settings</ModalHeader>
          <ModalCloseButton
            _hover={{
              color: "#22e9a0",
              borderRadius: "10px",
              border: "1px solid #22e9a0",
            }}
            borderRadius="10px"
          />
          <ModalBody>
            <Flex w="100%" alignItems="center" justifyContent="space-between">
              <Box>Slippage</Box>
              <Input
                borderRadius="10px"
                focusBorderColor="#22e9a0"
                type="number"
                placeholder="Enter slippage value"
                border="1px solid #181818"
                fontWeight="500"
                _placeholder={{
                  fontSize: "18px",
                  fontWeight: "500",
                }}
                w="50%"
                onChange={(e) => setSlippage(e.target.value)}
              />
            </Flex>
            <Divider mt="10px" mb="10px" />

            <Flex w="100%" alignItems="center" justifyContent="space-between">
              <Box>Insurance</Box>
              <Box>
                <Switch />
              </Box>
            </Flex>
            <Divider mt="10px" mb="10px" />
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>

      {/*  <Button onClick={() => addTrade()}>Do stuff</Button> */}

      {/* <Button onClick={() => onSwapStartedOpen()}>Do stuff</Button> */}
    </div>
  );
};

export default DEX;
