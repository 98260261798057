import { Link } from "@chakra-ui/react";

export const data = [
  {
    title: "The app isn't working. Why?",
    text: "It's advised that you use the app in an environment where a wallet is installed, such as inside of a wallet's dApp browser if you're on mobile. If the issue persists, send an email to support@degeninsure.com and well reach out as soon as possible.",
    isChecked: true,
  },
  {
    title: "How do the insurance plans work and what exactly is covered?",
    text: (
      <div>
        DegenInsure offers insurance for:
        <br />
        <br />
        <ul>
          <li>Hard rug pulls</li>
          <li>Abandoned projects</li>
        </ul>
        <br />
        <br />
        Here are the details surrounding these cover types, as well as the
        criteria to be eligible for cover{" "}
        <Link
          _hover={{
            color: "#22e9a0",
          }}
          href="https://degeninsure.gitbook.io/welcome/"
        >
          (you can find more info in our docs)
        </Link>{" "}
        :
        <br />
        <br />
        <ul>
          <li>
            For Hard Rug Pulls:
            <ul>
              <li>
                DEX where the token was traded must have been a well-known and
                established DEX, and must be based on Ethereum with at least
                $50M daily trading volume
              </li>
              <li>
                No ponzi schemes or projects with unusually high APYs (>100%
                APY) are covered. Equally, no Ohm forks or derivatives are
                covered.
              </li>
              <li>
                The project must rug at most 3 hours after its launch for full
                reimbursement, and you must still be holding 100% of your
                purchased tokens after the rug
              </li>
              <li>
                The token rugging cannot have been an obvious rug (unlocked
                liquidity, botted chat, no website, no roadmap, botted socials,
                etc.
              </li>
              <li>Newly made and unestablished meme tokens are not covered</li>
              <li>
                Substantial supply must not have been held by the deployer
              </li>
              <li>
                The launch must not have been heavily sniped or have weird
                pre-launch movement
                <li>Anti-whale measures must have been taken at launch</li>
                <li>
                  More than 2 influencers with scamming track record should not
                  be used for marketing purposes
                </li>
                <li>Deployer wallet must not have links to previous scams</li>
                <li>
                  No weird pre-launch token transfers (except publicly stated
                  ones to influencers)
                </li>
                <li>Pre-launch liquidity must be greater than 2 ETH</li>
                <li>No unusually high buy/sell tax (>5%)</li>
              </li>
              {/* Add other points here */}
            </ul>
          </li>
        </ul>
        <br />
        <br />
        <ul>
          <li>
            For Abandoned Projects:
            <ul>
              <li>
                The token devaluation must be 90% or higher from where you
                purchased
              </li>
              <li>
                The team must be totally inactive. No mods or team member
                presence in the group
              </li>
              <li>
                The project must have been abandoned within the time in which
                you purchased and activated your plan.
              </li>
              <li>
                You must not be holding >50% of your initial bag if you bought
                in early and had multiple chances to sell at great gains
              </li>
              <li>
                The project must have had a product or visible signs that they
                were building one (screenshots, GitHub repos, etc)
              </li>

              {/* Add other points here */}
            </ul>
          </li>
        </ul>
        <br />
        <br />
        <strong>Note:</strong> It's important to review your policy documents
        for specific details and exclusions.
      </div>
    ),
  },
  {
    title: "Will I prove ownership of my wallet?",
    text: "Yes, you will be required to prove ownership by either signing a message or sending a small amount of crypto that will be returned to you.",
    isChecked: true,
  },
  {
    title: "What if I don't hold $DGNS on the affected wallet?",
    text: "You will still be eligible for cover as long as you prove ownership of both wallets.",
    isChecked: true,
  },
  {
    title: "How long does it take to get paid after filing a claim?",
    text: "Our review process takes anywhere from three hours to a week depending on the amount covered and your response time to our queries, as well as our on-chain process. If we encounter any irregularities in the process of doing due diligence, your response time to our queries concerning the irregularities we found will hasten or delay your claim.",
    isChecked: true,
  },
  {
    title: "Do I need to hold $DGNS to file a claim?",
    text: "Yes, you need to hold $DGNS to file a claim, as well as purchase a plan.",
    isChecked: true,
  },
  {
    title: "How much can I get covered for?",
    text: "This depends on the amount of $DGNS supply you own. Please check our plans in our docs for more info.",
    isChecked: true,
  },
  {
    title: "Where do payouts come from?",
    text: "We charge premiums to users looking to purchase policies. In addition, 1% of our token tax goes towards the insurance fund.",
    isChecked: true,
  },
  {
    title: "Is DegenInsure safe?",
    text: "Yes. We never have access to your keys or assets throughout the connection and policy purchase process.",
    isChecked: true,
  },
  {
    title: "What is the maximum policy period?",
    text: "Policies can last a maximum of 2 weeks only due to the risky nature of crypto assets.",
    isChecked: true,
  },
  {
    title: "How are premiums calculated?",
    text: "Premiums are calculated based on the amount of assets you want to cover using a properietary formula that takes risk factor of your address into account.",
    isChecked: true,
  },
  {
    title:
      "What if I had purchased a plan but didn't hold the required amount of $DGNS?",
    text: "Unfortunately, you will not be eligible for cover in this case.",
    isChecked: true,
  },
  {
    title: "What if I bought a plan a day after I lost money?",
    text: "Payouts are only valid for users that purchased a plan before they lost money to a rug pull or abandoned project. Purchasing a plan after losing money guarantees 0 payout for past losses.",
    isChecked: true,
  },
  {
    title: "What happens after two weeks expire?",
    text: "Any plans purchased after two weeks are rendered obsolete, and new plans will have to be purchased to ensure cover.",
    isChecked: true,
  },
];
