import React, { useState, useEffect, useRef } from "react";
import { Flex, Box, Grid, GridItem, Icon, HStack } from "@chakra-ui/react";
import { useInView } from "framer-motion";

const Section8 = () => {
  const data = [
    {
      title: "5K $DGNS tokens",
      text: (
        <div>
          <div>Cover for up to $1,000</div>
          <ul style={{ "margin-left": "20px", "margin-top": "10px" }}>
            <li>
              <HStack>
                <Box>Hard Rug Pulls</Box>
                <Box>50%</Box>
              </HStack>
            </li>
            <li>
              <HStack>
                <Box>Slow rugs</Box>
                <Box>60%</Box>
              </HStack>
            </li>
          </ul>
        </div>
      ),
      icon: "Basic Cover",
    },
    {
      title: "7.5K $DGNS tokens",
      text: (
        <div>
          <div>Cover for up to $2,500</div>
          <ul style={{ "margin-left": "20px", "margin-top": "10px" }}>
            <li>
              <HStack>
                <Box>Hard Rug Pulls</Box>
                <Box>60%</Box>
              </HStack>
            </li>
            <li>
              <HStack>
                <Box>Slow rugs</Box>
                <Box>70%</Box>
              </HStack>
            </li>
          </ul>
        </div>
      ),
      icon: "Standard Cover",
    },
    {
      title: "10K $DGNS tokens",
      text: (
        <div>
          <div>Cover for up to $3,500</div>
          <ul style={{ "margin-left": "20px", "margin-top": "10px" }}>
            <li>
              <HStack>
                <Box>Hard Rug Pulls</Box>
                <Box>75%</Box>
              </HStack>
            </li>
            <li>
              <HStack>
                <Box>Slow rugs</Box>
                <Box>80%</Box>
              </HStack>
            </li>
          </ul>
        </div>
      ),
      icon: "Premium Cover",
    },

    {
      title: "15K $DGNS tokens",
      text: (
        <div>
          <div>Cover for up to $5,000</div>
          <ul style={{ "margin-left": "20px", "margin-top": "10px" }}>
            <li>
              <HStack>
                <Box>Hard Rug Pulls</Box>
                <Box>75%</Box>
              </HStack>
            </li>
            <li>
              <HStack>
                <Box>Slow rugs</Box>
                <Box>80%</Box>
              </HStack>
            </li>
          </ul>
        </div>
      ),
      icon: "Advanced Cover",
    },
    {
      title: "30K $DGNS tokens",
      text: (
        <div>
          <div>Cover for up to $7,000</div>
          <ul style={{ "margin-left": "20px", "margin-top": "10px" }}>
            <li>
              <HStack>
                <Box>Hard Rug Pulls</Box>
                <Box>85%</Box>
              </HStack>
            </li>
            <li>
              <HStack>
                <Box>Slow rugs</Box>
                <Box>90%</Box>
              </HStack>
            </li>
          </ul>
        </div>
      ),
      icon: "Elite Cover",
    },
    {
      title: "80K $DGNS tokens",
      text: (
        <div>
          <div>Cover for up to $10,000</div>
          <ul style={{ "margin-left": "20px", "margin-top": "10px" }}>
            <li>
              <HStack>
                <Box>Hard Rug Pulls</Box>
                <Box>90%</Box>
              </HStack>
            </li>
            <li>
              <HStack>
                <Box>Slow rugs</Box>
                <Box>95%</Box>
              </HStack>
            </li>
          </ul>
        </div>
      ),
      icon: "Ultra Degen Cover",
    },
  ];
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [currentColor, setCurrentColor] = useState("white");
  const colors = ["#ff00ff", "#6b32e3", "#20cc55", "#22e9a0"]; // Colors to sample through
  const transitionDuration = 2000;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentColor((prevColor) => {
        const currentIndex = colors.indexOf(prevColor);
        const nextIndex = (currentIndex + 1) % colors.length;
        const nextColor = colors[nextIndex];

        if (nextColor === colors[colors.length - 1]) {
          clearInterval(interval); // Stop the interval once the final color is reached
        }

        return nextColor;
      });
    }, transitionDuration);

    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <div>
      <Flex
        flexDir="column"
        mt="100px"
        w="100%"
        alignItems="center"
        justifyContent="center"
        ref={ref}
      >
        <Box fontSize="30px" textAlign="center">
          Plans tailor made for
          <Box
            style={{
              color: currentColor,
              transition: `color ${transitionDuration}ms ease-in-out`,
            }}
            as="span"
            display="inline-block"
            ml="3px"
          >
            everyone
          </Box>{" "}
        </Box>

        <Box
          mt="15px"
          color="gray.500"
          w={{ base: "80%", md: "70%" }}
          textAlign="center"
        >
          Hold some $DGNS to validate your plan purchase. Additionally, trade
          the USD value of the amount you hold in $DGNS at least once a week to
          be eligible for preference cover.
        </Box>

        <Grid
          mt="40px"
          templateRows={{ base: "repeat(6,1fr)", md: "repeat(2,1fr)" }}
          templateColumns={{ base: "repeat(1,1fr)", md: "repeat(3,1fr)" }}
          gap={10}
        >
          {data?.map((e, i) => {
            return (
              <GridItem
                w={{ base: "300px", md: "250px", lg: "300px" }}
                h="350px"
                borderRadius="20px"
                p="20px"
                background="linear-gradient(to bottom, #22e9a0, black)"
                style={{
                  transform: isInView ? "none" : "translateY(200px)",
                  opacity: isInView ? 1 : 0,
                  transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                }}
              >
                <Box>{e?.icon}</Box>

                <Box mt="10px" fontSize="25px">
                  {e?.title}
                </Box>
                <Box mt="10px" fontWeight="500">
                  {e?.text}
                </Box>

                <Box color="gray.500" fontSize="12px" mt="60px">
                  Ideally trade 50% in USD value of your holding amount in $DGNS
                  at least once a week to be eligible for preference cover.
                  Learn more in our docs.
                </Box>
              </GridItem>
            );
          })}
        </Grid>
      </Flex>
    </div>
  );
};

export default Section8;
