import React, { useState, useEffect, useRef } from "react";
import { Center, Image, Box, Flex } from "@chakra-ui/react";
import mockup1 from "../images/mockup1.png";
import mockup1mobile from "../images/mockup1mobile.png";
import "./Section1.css"; // Import the CSS file
import { useInView } from "framer-motion";

const Section1 = () => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [currentColor, setCurrentColor] = useState("white");
  const colors = ["#ff00ff", "#6b32e3", "#20cc55", "#22e9a0"]; // Colors to sample through
  const transitionDuration = 2000;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentColor((prevColor) => {
        const currentIndex = colors.indexOf(prevColor);
        const nextIndex = (currentIndex + 1) % colors.length;
        const nextColor = colors[nextIndex];

        if (nextColor === colors[colors.length - 1]) {
          clearInterval(interval); // Stop the interval once the final color is reached
        }

        return nextColor;
      });
    }, transitionDuration);

    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <div ref={ref}>
      <Box
        mt="100px"
        textAlign="center"
        fontSize={{ base: "30px", md: "56px" }}
        style={{
          transform: isInView ? "none" : "translateY(-200px)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
        }}
      >
        <Box
          style={{
            color: currentColor,
            transition: `color ${transitionDuration}ms ease-in-out`,
          }}
          color="#22e9a0"
          as="span"
          display="inline-block"
        >
          Protect{" "}
        </Box>{" "}
        your crypto experience.
      </Box>

      <Flex 
        style={{
          transform: isInView ? "none" : "translateY(-200px)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
        }}
        mt="20px"
        alignItems="center"
        justifyContent="center"
        w="100%"
      >
        {" "}
        <Box textAlign="center">
          Scammers abound. Stay ten steps ahead with DegenInsure.
        </Box>
      </Flex>

      <Center
        style={{
          transform: isInView ? "none" : "translateY(200px)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
        }}
        mt="60px"
        position="relative"
      >
        <Image
          display={{ base: "none", md: "yes" }}
          boxSize="70%"
          src={mockup1}
          zIndex="2"
          position="relative"
          boxShadow="0 0 20px rgba(0,0,0,0.5)" // Add a shadow to the image
        />

        <Image
          display={{ base: "yes", md: "none" }}
          boxSize="70%"
          src={mockup1mobile}
          zIndex="2"
          position="relative"
          boxShadow="0 0 20px rgba(0,0,0,0.5)" // Add a shadow to the image
        />
      </Center>
    </div>
  );
};

export default Section1;
