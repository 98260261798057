import { Box, Divider, Flex } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import AppNav from "../components/AppNav";
import Sidebar from "../components/Sidebar";
import { fetchUserByAddress } from "../helpers/getUserData";
import { degenInsureAbi } from "../resources/degenInsureAbi.js";
import { fetchInsuranceData } from "../helpers/getUserPlans";
import moment from "moment";
import { ethers } from "ethers";
import { useAccount } from "wagmi";

const Profile = () => {
  const { address } = useAccount();
  const [insuranceData, setInsuranceData] = useState({});
  const [userName, setUserName] = useState("");
  const [coverDate, setCoverDate] = useState("");
  const [coverValue, setCoverValue] = useState("");
  const [noOfPlans, setNoOfPlans] = useState(0);
  const [hexDate, setHexDate] = useState("");
  const [planss, setPlanss] = useState("");
  const [datas, setDatas] = useState([]);

  const getNumberOfUserCovers = async (
    contractAddress,
    contractAbi,
    userAddress
  ) => {
    try {
      // Initialize a Web3 provider
      const provider = new ethers.providers.Web3Provider(window.ethereum);

      // Instantiate the contract using the provided contract address and ABI
      const contract = new ethers.Contract(
        contractAddress,
        contractAbi,
        provider
      );

      // Call the insurances function to retrieve insurance data
      const insurance = await contract.getNumberOfCoverPlans(userAddress);

      // Return the retrieved insurance data
      return insurance;
    } catch (error) {
      /*    throw error; */
    }
  };

  const fetchUserPlans = async () => {
    const insure = await fetchInsuranceData(
      "0xe3168795300220712f90A3E68ac111BB75215291",
      degenInsureAbi,
      address
    );
    const plans = await getNumberOfUserCovers(
      "0xe3168795300220712f90A3E68ac111BB75215291",
      degenInsureAbi,
      address
    );
    setPlanss(plans);
    setInsuranceData(insure);
  };

  useEffect(() => {
    fetchUserPlans();
  }, [address]);

  const fetchDatas = async () => {
    const datasz = await fetchUserByAddress(address);
    setUserName(datasz?.name);
    setDatas(datasz);
  };

  useEffect(() => {
    fetchDatas();
  }, [address]);

  useEffect(() => {
    if (insuranceData && insuranceData.ethEquivalent) {
      const ethEquivalentValue = ethers.utils.formatUnits(
        insuranceData.ethEquivalent._hex,
        18
      );
      if (planss) {
        setNoOfPlans(planss.toString()); // Convert BigNumber to string
      }
      const decimalTimestamp = parseInt(insuranceData?.endTime?._hex, 16);
      const timestampMoment = moment?.unix(decimalTimestamp);
      const formattedTimestamp = timestampMoment.format("YYYY-MM-DD HH:mm:ss");
      setCoverDate(formattedTimestamp);
      setCoverValue(ethEquivalentValue);
    } else {
      setCoverValue("");
    }
  }, [insuranceData]);

  useEffect(() => {
    if (insuranceData && insuranceData.endTime) {
      const decimalTimestamp = parseInt(insuranceData.endTime._hex, 16);
      setHexDate(decimalTimestamp * 1000);
    }
  }, [insuranceData, address]);

  const calculateTimeRemaining = () => {
    const currentDate = new Date().getTime();
    const timeRemaining = hexDate - currentDate;

    if (timeRemaining <= 0) {
      // The target date has passed
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor(
      (timeRemaining % (1000 * 60 * 60)) / (1000 * 60)
    );
    const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  };

  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  useEffect(() => {
    const interval = setInterval(() => {
      const updatedTimeRemaining = calculateTimeRemaining();
      setTimeRemaining(updatedTimeRemaining);
    }, 1000);

    return () => clearInterval(interval);
  }, [hexDate]);

  return (
    <div>
      <AppNav />
      <Flex direction={{ base: "column", md: "row" }}>
        <Box>
          <Sidebar />
        </Box>
        <Flex
          justifyContent="center" // Center horizontally
          alignItems="center" // Center vertically
          flex="1" // Take up available space
          p={{ base: "20px", md: "30px" }}
          direction="column"
        >
          <Box
            mt={{ base: "0px", md: "200px", lg: "0px" }}
            w={{ base: "330px", md: "60%", lg: "40%" }}
          >
            <Box
              w={{ base: "340px", md: "400px" }}
              h={{ base: "320px", md: "300px" }}
              borderRadius="15px"
              p="20px"
              border="1.5px solid #181818"
            >
              <Flex w="100%" flexDir="column" alignItems="flex-start">
                <Flex
                  w="100%"
                  alignItems="flex-start"
                  justifyContent="space-between"
                >
                  <Box>Name</Box>
                  <Box color="#22e9a0">{userName}</Box>
                </Flex>

                <Divider mt="10px" mb="10px" />

                <Flex
                  w="100%"
                  alignItems="flex-start"
                  justifyContent="space-between"
                >
                  <Box>Address</Box>
                  <Box color="#22e9a0">
                    {address?.slice(0, 6) + "..." + address?.slice(-4)}
                  </Box>
                </Flex>

                <Divider mt="10px" mb="10px" />

                <Flex
                  w="100%"
                  alignItems="flex-start"
                  justifyContent="space-between"
                >
                  <Box>Active plans</Box>
                  <Box color="#22e9a0">{noOfPlans}</Box>
                </Flex>

                <Divider mt="10px" mb="10px" />

                <Flex
                  w="100%"
                  alignItems="flex-start"
                  justifyContent="space-between"
                >
                  <Box>Covered ETH</Box>
                  <Box color="#22e9a0">{coverValue} ETH</Box>
                </Flex>

                <Divider mt="10px" mb="10px" />
                <Flex
                  w="100%"
                  alignItems="flex-start"
                  justifyContent="space-between"
                >
                  <Box>DegenPoints</Box>
                  <Box color="#22e9a0">{datas?.degenPoints}</Box>
                </Flex>

                <Divider mt="10px" mb="10px" />

                <Flex
                  w="100%"
                  alignItems="flex-start"
                  justifyContent="space-between"
                >
                  <Box>Time left till cover expiry</Box>
                  <Box color="#22e9a0">
                    {timeRemaining?.days}d : {timeRemaining?.hours}h :{" "}
                    {timeRemaining?.minutes}m : {timeRemaining?.seconds}s
                  </Box>
                </Flex>
              </Flex>
            </Box>
          </Box>
        </Flex>
      </Flex>
    </div>
  );
};

export default Profile;
